import { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import {
    INDUCTION_SERVICES_DETAILS_DASHBOARD
} from 'App/Graphql/Queries/Reporting'
import { eachWord } from 'App/Helpers/utils'
import { Postulation, Spin } from 'App/Screens/AccreditedProfessionals/styles'
import {
    Bar,
    CartesianGrid,
    Legend,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis,
} from 'recharts'

import {
    StyledSpin,
    StyledSpinnerContainer,
    StyledTableDetails,
} from '../../../../styles'
import {
    DataElementsForDetailsDashboard,
    DataStatusCounterDetailsDashboard,
    DetailsDashboardDocument,
    DetailsServicesQueryResponse,
    DetailsTableType,
    Statuses
} from '../departments/types'
import {
    StyledBarChartDetails,
    StyledContainer,
    StyledDetailsContainer,
    StyledDetailsTableContainer,
    StyledDetailsTitle,
    StyledHeading4,
} from '../styles'

import { columnsTable, getFormattedDetailsData } from './helper'


const colors: string[] = [
    '#495FA4',
    '#50968B',
    '#6073C1',
    '#4FCAB8',
    '#87C54A',
    '#BAFF76',
    '#ffcb91',
    '#94ebcd',
    '#ffefa1',
]

const StylesTooltip = {
    border: '1px solid',
    background: 'white',
    padding: '5px',
    borderRadius: '5px',
}

export const getTextByStatus = (str: Statuses): string => {
    const status = {
        VALID: 'Vigente',
        NOT_VALID: 'No Vigente',
        NOT_ASSIGNED: 'No Asignado',
        PENDING: 'Pendiente',
        FULFILLED: 'Realizado',
        CATEGORIES: 'Categorias',
        DEPARTMENTS: 'Departamentos',
        SERVICES: 'Servicios',
        PLANTA: 'PLANTA',
        INVITADO: 'INVITADO',
        ACREDITADO: 'ACREDITADO',
        'RESIDENTE ASOCIADO': 'RESIDENTE ASOCIADO',
        ASOCIADO: 'ASOCIADO',
        RESIDENTE: 'RESIDENTE',
        ADJUNTO: 'ADJUNTO',
        'RESIDENTE REEMPLAZO': 'RESIDENTE REEMPLAZO',
        SENIOR: 'SENIOR',
    }

    return status[str]
}

const sortByCount = (
    array: DataStatusCounterDetailsDashboard[],
): DataStatusCounterDetailsDashboard[] =>
    Array.isArray(array) ? array.sort((a, b) => b.count - a.count * 1) : []

export const MedicalCensusServicesDetailsContent: FC<{
    title: string
    subtitle: string
}> = ({ title = 'Title example', subtitle = 'Subtitle example' }) => {
    const [data, setData] = useState<DetailsDashboardDocument>()
    const [detailsData, setDetailsData] = useState<DetailsTableType[]>()
    const { loading } = useQuery<DetailsServicesQueryResponse>(
        INDUCTION_SERVICES_DETAILS_DASHBOARD,
        {
            fetchPolicy: 'network-only',
            onCompleted: (data: DetailsServicesQueryResponse) => {
                setData(data.medicalCensusServicesDetailsDashboard)
                setDetailsData(
                    getFormattedDetailsData(
                        data.medicalCensusServicesDetailsDashboard,
                    ),
                )
            },
        },
    )

    const dataCharts = data?.elements
        .map((element: DataElementsForDetailsDashboard) => {
            const { name } = element
            let itemObj: any = {}

            data.listOfKeys.forEach((cat: string) => {
                itemObj = {
                    ...itemObj,
                    [eachWord(cat)]: 0,
                }
            })

            element.elements.forEach(({ name, count, total }) => {
                itemObj = {
                    ...itemObj,
                    total,
                    [eachWord(name)]: count,
                }
            })

            return { name: eachWord(name), ...itemObj, amt: 120 }
        })
        .sort((a: { total: number }, b: { total: number }) => b.total - a.total)

    const CustomTooltip = ({
       payload,
       active,
       label,
   }: TooltipProps<string, number>) => {
        const departmentName = () => {
            if (active && payload && payload.length)
                return (
                    <div>
                        <p className="label">{`${label} : (${
                            [...payload]?.shift()?.payload.total
                        })`}</p>
                    </div>
                )
        }

        return (
            <div style={StylesTooltip}>
                {departmentName()}
                {payload?.map(
                    (payload): JSX.Element => {
                        return (
                            <StyledHeading4
                                $headingColor={payload.color as string}
                                key={payload.name}>
                                {`${payload.name} : ${payload.value}`}
                            </StyledHeading4>
                        )
                    },
                )}
            </div>
        )
    }
    const addColors = (
        objElement: Pick<DetailsDashboardDocument, 'listOfKeys'>,
    ) =>
        [...objElement.listOfKeys].map((categoryName: string, idx: number) => ({
            categoryName: eachWord(categoryName),
            fillColor: colors[idx],
        }))
    const renderBar = () =>
        data &&
        addColors(data).map((item, idx: number) => (
            <Bar
                key={`${item.categoryName}__${idx + 1}`}
                dataKey={item.categoryName}
                stackId="a"
                fill={item.fillColor}
            />
        ))

    return (
        <StyledContainer>
            <StyledDetailsContainer>
                <Row>
                    <Col lg={5} xl={5} xxl={7}>
                        <StyledDetailsTitle>
                            <h1>{title}</h1>
                            <h3>{subtitle}</h3>
                            {loading ? (
                                <StyledSpinnerContainer>
                                    <Spin />
                                </StyledSpinnerContainer>
                            ) : (
                                <>
                                    {data?.counters &&
                                    sortByCount([...data.counters]).map(
                                        (counter: DataStatusCounterDetailsDashboard) => (
                                            <p key={`${counter.name}_${counter.count}`}>
                                                <strong>{counter.count}</strong>{' '}
                                                {getTextByStatus(counter.name as Statuses)}
                                            </p>
                                        ),
                                    )}
                                    <span>{data?.totalData}</span>
                                </>
                            )}
                        </StyledDetailsTitle>
                    </Col>
                    {loading ? (
                        <StyledSpinnerContainer>
                            <StyledSpin />
                        </StyledSpinnerContainer>
                    ) : (
                        <Col md={1} xl={1}>
                            <StyledBarChartDetails width={900} height={250} data={dataCharts}>
                                <CartesianGrid />
                                <XAxis
                                    dataKey="name"
                                    angle={-30}
                                    height={100}
                                    interval={0}
                                    dx={0}
                                    dy={0}
                                    textAnchor="end"
                                />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend verticalAlign="top" />
                                {renderBar()}
                            </StyledBarChartDetails>
                        </Col>
                    )}
                </Row>
            </StyledDetailsContainer>
            <StyledDetailsTableContainer>
                {loading ? (
                    <StyledSpinnerContainer>
                        <Spin />
                    </StyledSpinnerContainer>
                ) : (
                    <Postulation>
                        <StyledTableDetails
                            dataSource={detailsData}
                            rowKey="key"
                            columns={data && columnsTable(data)}
                            scroll={{ y: 440 }}
                            pagination={false}
                        />
                    </Postulation>
                )}
            </StyledDetailsTableContainer>
        </StyledContainer>
    )
}
