import { FC } from 'react'

import { HeaderMedicalCensusDepartmentsDetails } from '../departments/HeaderMedicalCensusDepartmentsDetails'

import { MedicalCensusServicesDetailsContent } from './MedicalCensusServicesDetailsContent'


export const ServicesCensusReportingDetails: FC = () => {
    return (
        <>
            <HeaderMedicalCensusDepartmentsDetails />
            <MedicalCensusServicesDetailsContent
                title="Servicios"
                subtitle="Personal Médico Activo actualmente"
            />
        </>
    )
}
