import React, { createContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Queries } from 'App/Graphql'
import { INameValueId } from 'App/Types/GlobalTypes'

export const AppStateContext = createContext({
  departments: [] as INameValueId[],
  subSpecialties: [] as INameValueId[],
  developments: [],
  services: [] as INameValueId[],
  categories: [] as INameValueId[],
  specialties: [],
  universities: [],
  loading: false,
  setLoading: (value: boolean): void | unknown => {
    return value
  },
  scrollToTop: () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
})

export const AppStateProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [departments, setDepartments] = useState<INameValueId[]>([])
  const [subSpecialties, setSubSpecialties] = useState<INameValueId[]>([])
  const [developments, setDevelopments] = useState([])
  const [categories, setCategories] = useState<INameValueId[]>([])
  const [services, setServices] = useState<INameValueId[]>([])
  const [specialties, setSpecialties] = useState([])
  const [universities, setUniversities] = useState([])

  useQuery(Queries.GET_ALL_DEPARTMENTS, {
    variables: {
      pagination: {
        limit: 200,
      },
    },
    onCompleted: data =>
      data && data.allDepartments && setDepartments(data.allDepartments),
  })
  useQuery(Queries.GET_ALL_SERVICES, {
    onCompleted: data =>
      data && data.allServices && setServices(data.allServices),
  })
  useQuery(Queries.GET_ALL_CATEGORIES, {
    onCompleted: data =>
      data && data.allCategories && setCategories(data.allCategories),
  })
  useQuery(Queries.GET_ALL_SPECIALTIES, {
    variables: {
      pagination: {
        limit: 200,
      },
    },
    onCompleted: data =>
      data && data.allSpecialties && setSpecialties(data.allSpecialties),
  })
  useQuery(Queries.GET_ALL_SUBSPECIALTIES, {
    variables: {
      pagination: {
        limit: 200,
      },
    },
    onCompleted: data => setSubSpecialties(data.allSubSpecialties),
  })
  useQuery(Queries.GET_ALL_UNIVERSITIES, {
    onCompleted: data =>
      data && data.allUniversities && setUniversities(data.allUniversities),
  })
  useQuery(Queries.GET_ALL_DEVELOPMENTS, {
    onCompleted: data =>
      data && data.allDevelopments && setDevelopments(data.allDevelopments),
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <AppStateContext.Provider
      value={{
        departments,
        developments,
        universities,
        subSpecialties,
        services,
        categories,
        specialties,
        loading,
        setLoading,
        scrollToTop,
      }}>
      {children}
    </AppStateContext.Provider>
  )
}
