import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Layout } from 'antd'
import { checkIfNull } from 'App/Helpers/utils'
import Provider from 'App/Providers'
import { AppStateContext } from 'App/Providers/AppState'
import { Auth } from 'aws-amplify'
import get from 'lodash/get'

import SvgHome from '../../assets/SVG/LogoHome'
import { Queries } from '../../Graphql'
/* import { IHomeScreen } from '../../Types/HomeScreenTypes' */
import LogOutModal from '../LoginScreen/components/LogOut'

import useGetQuery from './../../Helpers/CustomHooks/useGet'
import Context from './../../Providers'
import SearchComponent from './components/SearchComponent'
import { SideMenu } from './SideMenu'
import {
  AvatarContainer,
  Header,
  HomeContent,
  SiderHome,
  StyledLayout,
} from './styles'

const HomeScreen: React.FC<RouteComponentProps> = ({ children, history }) => {
  const { data, loading } = useGetQuery(Queries.GET_USER_SESSION)
  const session = get(data, 'session')
  const { setRole, setId } = useContext(Context.SessionContext)

  useEffect(() => {
    Auth.currentSession()
      .then(data => data)
      .catch(() => {
        return history.push('/login')
      })
  }, [history])

  useEffect(() => {
    const isSessionNull = checkIfNull(session)

    !isSessionNull && setRole(session.role)
    !isSessionNull && setId(session._id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const { loading: appLoading } = useContext(AppStateContext)

  return (
    <Provider.AppStateProvider>
      <StyledLayout $loading={appLoading}>
        <Header>
          <SvgHome to={'/'} />
          <SearchComponent />
          {/* <SearchButton size='large'>Búsqueda Avanzada</SearchButton>*/}
          <AvatarContainer>
            {/* <Badge>*/}
            {/*  <Avatar size='default' icon={<BellFilled />} />*/}
            {/* </Badge>*/}
            <LogOutModal session={session} loading={loading} />
          </AvatarContainer>
        </Header>
        <Layout>
          <SiderHome>
            <SideMenu />
          </SiderHome>
          <Layout>
            <HomeContent>{children}</HomeContent>
          </Layout>
        </Layout>
      </StyledLayout>
    </Provider.AppStateProvider>
  )
}

export default withRouter(HomeScreen)
