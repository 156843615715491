/* eslint-disable arrow-parens */
import { Form, Input, InputProps, Row, RowProps, Select } from 'antd'
import styled, { css } from 'styled-components'

const { Item } = Form
const { Option } = Select

interface StyledRowProps extends RowProps {
  justifyprop?: string
}

type Heading4 = {
  $headingColor: string
}

export const StyledRow = styled(Row)<StyledRowProps>`
  ${(props) =>
    props.justifyprop &&
    css`
      display: flex;
      justify-content: ${props.justifyprop} !important;
    `}
  margin: 10px -40px 0 0;
`

export const StyledFormItem = styled(Item)`
  .ant-form-item-label > label {
    color: #4e4e4f !important;
    font-weight: 600 !important;
  }
`

interface StyledInputComponentProps extends InputProps {
  w?: number
}

export const StyledInputComponent = styled(Input)<StyledInputComponentProps>`
  margin-bottom: -10px !important;
  color: #898989 !important;
  width: ${(props) => (props.w ? props.w : 91)}%;
  height: 40px;
  border-radius: 6px !important;
  font-weight: 400 !important;
  background-color: #f5f5f5 !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const StyledSelectComponent = styled(Select)`
  margin: 0 35px 0 0 !important;
  .ant-select-selector {
    color: #898989 !important;
    border-radius: 6px !important;
    background-color: #f5f5f5 !important;
    font-weight: 400 !important;
    font-size: 14px;
    margin: 0 36px 0 0 !important;
    padding: 0 20px 0 0 !important;
    &:hover {
      border-color: #abd838 !important;
    }
  }
  .ant-select-clear {
    margin: -5px 40px 0 0 !important;
  }
  .ant-select-focused {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select-open {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select {
    &::selection {
      border-color: #abd838 !important;
    }
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
`

export const StyledSelectOptions = styled(Option)`
  .ant-select-selection-search {
    font-weight: normal !important;
  }
`

export const StyledDashboardContainerFather = styled.div`
  margin: 20px;
  h1 {
    color: #abd838;
    font-weight: bold;
  }
`

export const StyledHeaderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 5px 5px -30px;
`

export const StyledTitleHeaderDetails = styled.div`
  display: flex;
  h1 {
    color: #2d3f8c;
    cursor: pointer;
    font-size: 30px;
    margin: 0 0 0 30px;
  }
  h4 {
    margin: 60px 0 0 -315px;
    color: #abd838;
    font-weight: bold;
  }
  .anticon {
    color: #2d3f8c;
    font-weight: bold;
    cursor: pointer;
    font-size: 25px;
    margin: 15px 0 0 35px;
  }
`

export const StyledContainer = styled.div`
  margin: 0 30px 0 30px;
`

export const StyledDetailsContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  height: 295px;
  margin: 5px 0 20px 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 3px 2px #dbdbdb;
`

export const StyledDetailsTableContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  height: 100%;
  margin: 5px 0 20px 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 3px 2px #dbdbdb;
`

export const StyledDetailsTitle = styled.div`
  padding: 25px 0 0 25px;
  h1 {
    color: #495fa4;
    font-size: 25px;
  }
  h3 {
    color: #b9b8b7;
    margin: -15px 0 10px 0;
  }
  p {
    color: #4e4e4f;
    margin-bottom: 0;
  }
  span {
    color: #495fa4;
    font-size: 60px;
  }
`

export const StyledHeading4 = styled.h4<Heading4>`
  color: ${(props: Heading4) =>
    props.$headingColor ? props.$headingColor : '#abd838'};
  font-weight: bold;
`
