import { gql } from '@apollo/client'

export const GET_ALL_PROFESSIONS = gql`
  query allProfessions {
    allProfessions {
      _id
      name
    }
  }
`

export const GET_ALL_UNIVERSITIES = gql`
  query allUniversities {
    allUniversities {
      _id
      name
    }
  }
`

export const GET_ALL_SPECIALTIES = gql`
  query allSpecialties($pagination: PaginationInput) {
    allSpecialties(pagination: $pagination) {
      _id
      name
    }
  }
`

export const GET_ALL_INSTITUTIONS = gql`
  query allInstitutions {
    allInstitutions {
      _id
      name
    }
  }
`

export const GET_ALL_SUBSPECIALTIES = gql`
  query allSubSpecialties ($pagination: PaginationInput) {
    allSubSpecialties (pagination: $pagination) {
      _id
      name
    }
}
`

export const GET_ALL_DEVELOPMENTS = gql`
  query allDevelopments {
    allDevelopments {
      _id
      name
    }
  }
`

export const GET_ALL_COMPANIES = gql`
  query allCompanies {
    allCompanies {
      _id
      name
    }
  }
`
