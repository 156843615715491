import * as notification from '../../../Helpers/Notification'
import { ModalStyled as StyledModal } from '../styles'
import {
  AuthorizedBySaveProps,
  DeleteOrMoveFileEnum,
  HandleChangeUploadProps,
  ShowConfirmModalProps,
} from '../type'

const allowedTypes = ['application/pdf']
const allowedTypesImage = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
]
const maximumSize = 5 * 1024 * 1024 // 5 MB

export const showConfirmModal = ({
  document,
  getUrlForDelete,
  uploadListDoc,
  setKindOfAction,
}: ShowConfirmModalProps) => {
  StyledModal.confirm({
    title: `¿Quiere eliminar el archivo ${document}?`,
    content: 'Eliminará el archivo permanentemente',
    width: '450px',
    okText: 'Sí, quiero eliminarlo',
    okType: 'danger',
    onOk() {
      setKindOfAction(DeleteOrMoveFileEnum.DELETE)
      getUrlForDelete({ variables: { key: uploadListDoc.key } })

      return false
    },
    onCancel() {
      return false
    },
  })

  return false
}

export const handleChangeUpload = ({
  file,
  professional,
  listType,
  setFileToUpload,
}: HandleChangeUploadProps) => {
  if (
    (!allowedTypes.includes(file.file.type) && listType === 'text') ||
    (!allowedTypesImage.includes(file.file.type) && listType === 'picture-card')
  ) {
    notification.openWarningNotification('¡Advertencia!')(
      'Tipo de archivo no permitido',
    )

    return false
  } else if (file.file.size > maximumSize) {
    const maximumSizeReadable = maximumSize / (1024 * 1024)

    notification.openWarningNotification('¡Advertencia!')(
      `Archivo supera el límite de ${maximumSizeReadable} MB.`,
    )

    return false
  } else if (!professional.code) {
    notification.openErrorNotification('¡No tiene código médico!')(
      `El  profesional no posee código médico`,
    )

    return false
  }
  setFileToUpload(file)
}

export const authorizedBySave = ({
  authorizedByName,
  authorizedBy,
  professional,
  authorizedById,
}: AuthorizedBySaveProps) => {
  if (authorizedByName) {
    authorizedByName === 'vaccine' &&
      authorizedBy &&
      authorizedBy({
        variables: {
          _id: professional._id,
          input: { vaccine: { authorizedBy: authorizedById } },
        },
      })
    authorizedByName === 'radiological' &&
      authorizedBy &&
      authorizedBy({
        variables: {
          _id: professional._id,
          input: { radiological: { authorizedBy: authorizedById } },
        },
      })
  }
}
