import { FC, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useLazyQuery } from '@apollo/client'
import { Col, Form } from 'antd'
import {
  StyledFormItem,
  StyledInputComponent,
} from 'App/GlobalComponents/styles'
import { ALL_PROFESSIONALS_FILTERED } from 'App/Graphql/Queries/Reporting'
import * as notification from 'App/Helpers/Notification'
import dayjs from 'dayjs'
import * as R from 'ramda'

import { StyledCreateReportButton } from '../GeneralInformation/styles'
import { FilterFormItems } from '../types'

const todayAsStringFormat = dayjs(new Date()).format('DD_MM_YYYY')
const nameReport = `reporte_professionales_${todayAsStringFormat}`

type CreateReportButtonProps = {
  formFilter: FilterFormItems
}

export const CreateReportButton: FC<CreateReportButtonProps> = ({
  formFilter,
}) => {
  const [form] = Form.useForm()
  const csvLink = useRef<any>()
  const [fileName, setFileName] = useState<string>(nameReport)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [dataCsv, setDataCsv] = useState<string>('')
  const handleChangeValues = (_value: { nameReport: string }) => {
    if (!R.isEmpty(_value.nameReport)) {
      setButtonDisabled(false)
      setFileName(_value.nameReport)
    }

    if (R.isEmpty(_value.nameReport)) {
      setButtonDisabled(true)
      setFileName(_value.nameReport)
    }
  }
  const [handleFetch, { loading }] = useLazyQuery(ALL_PROFESSIONALS_FILTERED, {
    onCompleted: (data: { allProfessionalsFiltered: string }) => {
      data.allProfessionalsFiltered && setDataCsv(data.allProfessionalsFiltered)
      // document.getElementById('csv-link')?.click()
      // csvLink.current.link.click()
      // notification.openSuccessNotification('¡Descarga Finalizada!')('')
    },
    onError: () =>
      notification.openErrorNotification('¡No se pudo Descargar!')(
        'No se pudo descargar el reporte.',
      ),
    fetchPolicy: 'cache-and-network',
  })

  const handleClickOfCSVLink = () => {
    try {
      setTimeout(() => {
        document.getElementById('csv-link')?.click()
        notification.openSuccessNotification('¡Descarga Finalizada!')('')
        // setLoading(false)
      }, 5000)
      notification.openWarningNotification('¡Iniciando descarga!')(
        'Ha comenzado la descarga del reporte personalizado. Por favor, espere.',
      )
      // setLoading(true)
    } catch (err) {
      notification.openErrorNotification('¡No se pudo Descargar!')(
        'No se pudo descargar el reporte.',
      )
    }
    handleFetch({
      variables: {
        input: formFilter,
      },
    })
  }

  return (
    <Form
      form={form}
      name="create-report-form"
      layout="vertical"
      initialValues={{ nameReport }}
      onValuesChange={handleChangeValues}>
      <Col md={24} xl={24}>
        <StyledFormItem label="Nombre del reporte" name="nameReport">
          <StyledInputComponent
            type="text"
            autoComplete="off"
            placeholder="Nombre del reporte"
            size="large"
            w={100}
          />
        </StyledFormItem>
      </Col>
      <Col md={24} xl={24}>
        <StyledCreateReportButton
          onClick={handleClickOfCSVLink}
          loading={loading}
          disabled={buttonDisabled}>
          Crear Reporte
        </StyledCreateReportButton>
        <CSVLink
          id="csv-link"
          filename={`${fileName ?? nameReport}.csv`}
          style={{ display: 'none' }}
          ref={csvLink}
          data={dataCsv}
          separator={';'}>
          Download me
        </CSVLink>
      </Col>
    </Form>
  )
}
