import React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { PRIVILEGES_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

const InfectionCourses: React.FC = () => (
  <Col span={8}>
    <StyledFormItem label="Curso de Infección" name="infectionCourses">
      <StyledSelectComponent
        mode="multiple"
        placeholder="Curso de Infección"
        allowClear
        size="large">
        {PRIVILEGES_STATUSES
          ? renderMultipleOptions(PRIVILEGES_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default InfectionCourses
