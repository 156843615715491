import { gql } from '@apollo/client'

export const UPDATE_PROFESSIONAL = gql`
  mutation updateProfessional(
    $_id: ID!
    $input: ProfessionalUpdateInput!
    $tab: String
  ) {
    updateProfessional(_id: $_id, input: $input, tab: $tab) {
      status
      rut
      firstName
      code
    }
  }
`
