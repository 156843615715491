import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Divider } from 'antd'
import {
  GET_ALL_CATEGORIES,
  GET_ALL_DEPARTMENTS,
  GET_ALL_SERVICES,
  GET_PROFESSIONAL_SEARCH,
} from 'App/Graphql/Queries'
import { eachWord, homologueStatus, toPascalCase } from 'App/Helpers/utils'
import { IProfessional } from 'App/Types/GlobalTypes'
import {
  Category,
  ProfessionalServicesInterface,
} from 'App/Types/ProfessionalTypes'
import get from 'lodash/get'

import { columns } from './helper'
import { SearchLabel, SearchResult, SearchTable, Spin } from './styles'
import { AccreditedColumnsType } from './types'

export const SearchScreen: FC = () => {
  const history = useHistory()
  const [professionalData, setProfessionalData] = useState<
    IProfessional[] | undefined
  >(undefined)

  const query = new URLSearchParams(window.location.search).get('q')

  const { data: dataAllDepartments } = useQuery(GET_ALL_DEPARTMENTS)
  const { data: dataAllServices } = useQuery(GET_ALL_SERVICES)
  const { data: dataAllCategories } = useQuery(GET_ALL_CATEGORIES)
  const { data, loading } = useQuery(GET_PROFESSIONAL_SEARCH, {
    variables: {
      input: {
        simpleText: query,
      },
    },
    fetchPolicy: 'network-only',
  })

  const searchProfessionals = get(data, 'searchProfessionals')

  useEffect(() => {
    setProfessionalData(
      searchProfessionals &&
        dataAllServices &&
        dataAllDepartments &&
        dataAllCategories &&
        searchProfessionals.map((el: IProfessional) => {
          const servicesNames = (el.services as ProfessionalServicesInterface[])
            .map((serviceProfessional: ProfessionalServicesInterface) =>
              toPascalCase(
                dataAllServices.allServices.find(
                  (service: { _id: string }) =>
                    service._id === serviceProfessional.service,
                )?.name,
              ),
            )
            .join(', ')
          const categoryName = (el.categories as Category[])
            .map((categoryProfessional: Category) =>
              toPascalCase(
                dataAllCategories.allCategories.find(
                  (category: { _id: string }) =>
                    category._id === categoryProfessional?.category,
                )?.name,
              ),
            )
            .pop()
          const departmentName = () =>
            dataAllDepartments.allDepartments.find(
              (department: { _id: string }) =>
                department._id === el.department?.department,
            )?.name

          return {
            id: el._id,
            key: `${el._id}_${(el.firstSurname as string).toLowerCase()}`,
            name: `${eachWord(el.firstSurname as string)} ${eachWord(
              el.secondSurname as string,
            )}${el.firstSurname && ', '}${eachWord(el.firstName as string)}`,
            rut: el.rut,
            services: servicesNames ? servicesNames : 'Sin servicio',
            categories: categoryName ?? 'Sin categoría',
            department: toPascalCase(departmentName()) ?? 'Sin departamento',
            status: homologueStatus(el.status as string),
            code: el.code,
          }
        }),
    )
  }, [
    dataAllCategories,
    dataAllDepartments,
    dataAllServices,
    searchProfessionals,
  ])

  const startAccreditation = (record: AccreditedColumnsType) => ({
    onClick: () => history.push(`/profesional/${record.id}`),
  })

  useEffect(() => {
    if (loading) setProfessionalData(undefined)
  }, [loading])

  return (
    <>
      {loading || !professionalData ? (
        <div>
          <Spin style={{ margin: '200px 550px' }} size="large" />
        </div>
      ) : (
        <SearchResult>
          <h2 style={{ color: '#CECECE' }}>
            Resultados de búsqueda:
            <SearchLabel>{query}</SearchLabel>
          </h2>
          <Divider />
          <SearchTable
            onRow={record => startAccreditation(record)}
            pagination={{ defaultPageSize: 8 }}
            columns={columns}
            dataSource={professionalData}
            rowKey="key"
          />
        </SearchResult>
      )}
    </>
  )
}
