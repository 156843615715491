import { Button, Col, Form, Row } from 'antd'
import styled from 'styled-components'

const { Item } = Form

export const Span = styled.span`
  font-weight: 600;
  font-size: 12px;
  font-family: 'Lato', normal;
  letter-spacing: 0px;
  color: #b9b9b9;
  opacity: 1;
`

export const SvgVersionRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const VersionCol = styled(Col)`
  color: #b9b9b9;
  margin-left: auto;
`

export const HeaderLogin = styled.div`
  text-align: center;
  margin: 10px 0px 60px;
  & h2 {
    margin-bottom: 0px;
    letter-spacing: 0px;
    color: #b9b9b9;
    font-weight: bold;
    font-family: 'Lato', normal;
    opacity: 1;
  }
  & h3 {
    letter-spacing: 0px;
    color: #b9b9b9;
    font-family: 'Lato', normal;
    opacity: 1;
  }
`

export const LinkForgotPassword = styled(Button)`
  float: right;
  color: #2d3f8c !important;
`

export const LoginRow = styled(Row)`
  margin-top: 5%;
  /* Portrait and Landscape */
  @media screen {
    margin-top: 1rem;
  }
  @media (max-width: 1010px) {
    margin-top: 1rem;
  }
`

export const LoginCol = styled(Col)`
  border: 5px solid #2d3f8c;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  padding: 50px !important;

  /* Portrait and Landscape */
  // https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
  @media (max-width: 1010px) {
    min-width: 95% !important;
    padding: 40px !important;
  }
`

export const LoginButton = styled(Button)`
  height: 50px;
  border-radius: 5px !important;
  background-color: #2e3e8b !important;
  color: white !important;
  margin-right: 25px;
`

export const ColForm2 = styled(Col)`
  border: 5px solid #2d3f8c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
  padding: 5%;
  background: #2e3e8b;
  @media (max-width: 1010px) {
    display: none;
  }
`

export const FormItem = styled(Item)`
  .ant-form-item-label > label {
    color: #2d3f8c !important;
  }
`

// LogOut icon
export const LogOutIcon = styled.a`
  display: row;
  margin: 10px;
  color: #343b4c;
  .avatar-styles {
    margin-left: 25px;
    margin-right: 10px;
  }
`

// Restore Password
export const TitleHeader = styled.h4`
  width: 100%;
  color: #2d3f8c;
  font-weight: bold;
  text-align: center;
  padding: 5px 0px;
`

export const ResetButton = styled(Button)`
  float: right;
  color: #2d3f8c !important;
`

export const ReturnButton = styled(Button)`
  color: #2d3f8c !important;
`

export const AlertStyle = styled.div`
  margin: 20px 0px;
  width: 100%;
`
