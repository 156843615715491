import React from 'react'
import { Form, Input } from 'antd'
import { IRestoreForm } from 'App/Types/LoginScreenTypes'

import { FormItem, LoginButton } from '../../../styles'

const RestoreForm = ({
  code,
  codeValidate,
  confirmPassword,
  confirmPasswordValidate,
  disabledButton,
  handleConfirmResetPassword,
  isTempBlocked,
  loading,
  newPassword,
  newPasswordValidate,
  setCode,
  setCodeValidate,
  setConfirmPassword,
  setConfirmPasswordValidate,
  setNewPassword,
  setNewPasswordValidate,
  userName,
}: IRestoreForm): any => {
  return (
    <Form autoComplete="off" layout="vertical">
      <FormItem label="Email">
        <Input value={userName} disabled />
      </FormItem>
      {isTempBlocked === false && (
        <React.Fragment>
          <FormItem
            label="Código"
            name="code"
            hasFeedback
            validateStatus={codeValidate}
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el código',
              },
            ]}>
            <Input
              placeholder="Código"
              value={code}
              onChange={e => {
                setCode(e.target.value.trim())
                e.target.value.trim().length < 6
                  ? setCodeValidate('error')
                  : setCodeValidate('success')
              }}
            />
          </FormItem>
          <FormItem
            label="Nueva Contraseña"
            name="newPassword"
            hasFeedback
            validateStatus={newPasswordValidate}
            dependencies={['code']}
            rules={[
              {
                required: true,
                message:
                  'Por favor ingrese su contraseña, mínimo 12 caracteres',
                min: 12,
              },
            ]}>
            <Input
              placeholder="Nueva Contraseña"
              value={newPassword}
              onChange={e => {
                setNewPassword(e.target.value.trim())
                e.target.value.trim().length < 12
                  ? setNewPasswordValidate('error')
                  : setNewPasswordValidate('success')
              }}
              type="password"
            />
          </FormItem>
          <FormItem
            label="Confirme Contraseña"
            name="confirmPassword"
            dependencies={['newPassword']}
            hasFeedback
            validateStatus={confirmPasswordValidate}
            rules={[
              {
                required: true,
                message: 'Las contraseñas deben coincidir',
                min: 12,
              },
            ]}>
            <Input
              placeholder="Confirme Contraseña"
              value={confirmPassword}
              onChange={e => {
                setConfirmPassword(e.target.value.trim())
                e.target.value.trim() === newPassword
                  ? setConfirmPasswordValidate('success')
                  : setConfirmPasswordValidate('error')
              }}
              type="password"
            />
          </FormItem>
          <Form.Item>
            <LoginButton
              disabled={disabledButton}
              type="ghost"
              htmlType="submit"
              loading={loading}
              block
              onClick={handleConfirmResetPassword}>
              Enviar
            </LoginButton>
          </Form.Item>
        </React.Fragment>
      )}
    </Form>
  )
}

export default RestoreForm
