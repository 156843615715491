/* eslint-disable arrow-parens */
import {
  Avatar as AvatarComponent,
  Button,
  Layout,
  Menu as SiderMenu,
} from 'antd'
import Search from 'antd/lib/input/Search'
import styled, { css } from 'styled-components'

const { Sider: SiderComponent, Content: ContentComponent } = Layout
// const { Search: SearchComponent } = Input;
const { Item } = SiderMenu

export interface GlobalProps {
  $loading?: boolean
}

export const StyledLayout = styled(Layout)<GlobalProps>`
  * {
    ${(props) =>
      props.$loading &&
      css`
        cursor: wait !important;
      `}
  }
`

export const Header = styled.div`
  margin: -10px 0 -10px -10px;
  height: 85px;
  background: #ffffff !important;
  padding: 10px;
  & > div {
    padding-top: 5px;
    margin-top: auto;
  }
`

// export const Search = styled(Input)`
//   width: 30% !important;
//   margin: -0px 20px !important;
//   background: #ffffff 0% 0% no-repeat padding-box !important;
//   border-radius: 5px !important;
//   opacity: 1 !important;
// `

export const AvatarContainer = styled.div`
  margin: 10px;
  align-items: center;
  display: flex;
  float: right;
`

export const Avatar = styled(AvatarComponent)`
  background-color: 'green';
  vertical-align: 'middle';
  margin-left: 60px;
`

export const HomeContent = styled(ContentComponent)`
  padding: 24;
  margin: 0;
  min-height: 280;
  color: blue;
  background-color: #f9f9f9 !important;
`

export const SiderHome = styled(SiderComponent)`
  width: 480px;
`

export const StyledDistinctive = styled.div`
  background-color: #2d3f8c;
  margin-top: -6px;
  white-space: normal;
  p {
    margin: 150% 0 370% 6px;
    color: white;
    font-size: 20px;
    display: inline-block;
  }
`

export const Menu = styled(SiderMenu)`
  height: 100% !important;
  border-right: 0;

  aside {
    min-width: 210px;
    max-width: 210px;
  }

  .ant-menu-item-selected {
    background-color: #e4e6f0 !important;
  }

  .ant-menu-item {
    &:after {
      border-right: 3px solid #abd838 !important;
    }
  }

  .ant-menu-item-selected a {
    color: #495fa3 !important;

    &:hover {
      color: rgba(164, 163, 163, 0.6) !important;
    }
  }

  a {
    color: #b9b8b6 !important;

    &:hover {
      color: rgba(28, 27, 27, 0.6) !important;
    }
  }
`

export const MenuItem = styled(Item)``

export const ContentLayout = styled(Layout)`
  margin: 0px;
`

export const SearchButton = styled(Button)`
  border-radius: 5px;
  border: 2px solid #2d3f8c;
  padding: 0px 40px;
  color: #2d3f8c !important;
  &:hover {
    background: #2d3f8c;
    color: #ffff !important;
  }
`

export const SearchBar = styled(Search)`
  margin: 10px;
  width: 500px;

  input {
    background-color: #f9f9f9;
    border-color: #f9f9f9;

    :hover,
    :focus {
      border-right: 0;
      border-color: #f9f9f9 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .ant-input-search-button {
    color: #cecece !important;
    border-left: 0;
    border-color: #f9f9f9;
    background-color: #f9f9f9;

    :hover,
    :focus,
    :active {
      border-color: #f9f9f9;
    }
  }

  & [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
`
