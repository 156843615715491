import { gql } from '@apollo/client'

const CREATE_PROFESSIONAL = gql`
  mutation createProfessional($input: CreateProfessionalInput!) {
    createProfessional(input: $input) {
      _id
      firstName
      code
    }
  }
`

export { CREATE_PROFESSIONAL }
