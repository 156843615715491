import { useState } from 'react'
import { Alert, Form, Input, notification } from 'antd'
import { IRestorePassword } from 'App/Types/LoginScreenTypes'
import { Auth } from 'aws-amplify'

import * as Styled from '../../styles'

import RestoreForm from './RestoreForm'

const RestorePassword = ({
  loading,
  userName,
  setActiveResetPassword,
  setLoading,
  setUserName,
}: IRestorePassword): any => {
  const [code, setCode] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState(
    '' as 'success' | 'info' | 'warning' | 'error',
  )
  const [showAlert, setShowAlert] = useState(false)
  const [userNameValidate, setUserNameValidate] = useState(
    '' as '' | 'success' | 'warning' | 'error' | 'validating',
  )
  const [codeValidate, setCodeValidate] = useState('')
  const [newPasswordValidate, setNewPasswordValidate] = useState('')
  const [confirmPasswordValidate, setConfirmPasswordValidate] = useState('')
  const [isTempBlocked, setIsTempBlocked] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const handleResetPassword = () => {
    setShowAlert(true)

    if (userNameValidate !== 'success') {
      setAlertType('error')

      if (userName.length === 0) setAlertMessage('Este campo es obligatorio')
      else setAlertMessage('Ingrese un correo válido')
    } else {
      setCodeSent(true)
      Auth.forgotPassword(userName)
        .then(() => console.log(''))
        .catch(err => {
          // console.log(err)
          if (err.code === 'LimitExceededException') {
            setIsTempBlocked(true)
            setAlertType('error')
            setAlertMessage(
              'Usuario blockeado temporalmente por exceder limite de intentos, prueba más tarde',
            )
          }
        })
      setAlertType('info')
      setAlertMessage(
        'Si su usuario es valido, será enviado a su correo un código de validación',
      )
    }
  }

  const handleConfirmResetPassword = () => {
    setDisabledButton(true)
    setShowAlert(true)

    if (
      code.length === 0 ||
      newPassword.length === 0 ||
      confirmPasswordValidate.length === 0
    ) {
      setAlertType('error')
      setAlertMessage('Todos los campos son obligatorios')
      setDisabledButton(false)

      return
    }

    if (code.trim().length < 6) {
      setAlertType('error')
      setAlertMessage('Hubo un error con su codigo, intentelo nuevamente')
      setDisabledButton(false)

      return
    }

    if (newPassword.trim().length < 12 || confirmPassword.trim().length < 12) {
      setAlertType('error')
      setAlertMessage(
        'Hubo un error, inténtelo de nuevo. Recuerda que las contraseñas son mínimo 12 caracteres',
      )
      setDisabledButton(false)

      return
    }
    Auth.forgotPasswordSubmit(userName, code, newPassword)
      .then(() => {
        // data
        setAlertMessage(
          'Ha cambiado su contraseña correctamente. Por favor, inicie sesión con sus nuevas credenciales',
        )
        setLoading(true)
        setAlertType('success')
        setTimeout(() => {
          setUserName('')
          setCode('')
          setNewPassword('')
          setConfirmPassword('')
          setActiveResetPassword(false)
          setLoading(false)
          notification.success({
            message: 'Su contraseña se ha cambiado exitosamente',
          })
          setDisabledButton(false)
        }, 5000)
      })
      .catch(() => {
        // err
        setAlertMessage('Hubo un error, por favor introduzca una nueva contraseña, esta debe contar con al menos un número, mayúsculas, minúsculas y algún carácter especial (Ej: . @ / ? + - :)')
        setDisabledButton(false)
        setAlertType('error')
      })
  }

  const handleUserNameValidation = (e: any) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const status = re.test(String(e.target.value).toLowerCase())

    status ? setUserNameValidate('success') : setUserNameValidate('error')
  }

  const handleAlreadyHavePassword = () => {
    setShowAlert(true)

    if (userNameValidate !== 'success') {
      setAlertType('error')

      if (userName.length === 0) setAlertMessage('El campo es obligatorio')
      else setAlertMessage('Ingrese un correo válido')
    } else {
      setCodeSent(true)
      setAlertType('info')
      setAlertMessage('Ingrese el código y su nueva contraseña')
    }
  }

  return (
    <>
      <Styled.TitleHeader>Ingresa tus datos para restablecer contraseña</Styled.TitleHeader>
      {showAlert && (
        <Styled.AlertStyle>
          <Alert message={alertMessage} type={alertType} showIcon />
        </Styled.AlertStyle>
      )}
      {!codeSent ? (
        <Form layout="vertical" name="resetPassword">
          <Styled.FormItem
            label="Email"
            name="username"
            hasFeedback
            validateStatus={userNameValidate}>
            <Input
              value={userName}
              placeholder="Ingrese correo"
              onChange={e => setUserName(e.target.value.trim())}
              onKeyUp={handleUserNameValidation}
            />
          </Styled.FormItem>
          <Form.Item>
            <Styled.LoginButton
              id="submit-btn"
              htmlType="submit"
              type="ghost"
              block
              loading={loading}
              onClick={handleResetPassword}>
              Enviar
            </Styled.LoginButton>
            <Styled.ResetButton type="link" onClick={handleAlreadyHavePassword}>
              Ya tengo mi código
            </Styled.ResetButton>
          </Form.Item>
        </Form>
      ) : (
        <RestoreForm
          code={code}
          codeValidate={codeValidate}
          confirmPassword={confirmPassword}
          confirmPasswordValidate={confirmPasswordValidate}
          disabledButton={disabledButton}
          setDisabledButton={setDisabledButton}
          handleConfirmResetPassword={handleConfirmResetPassword}
          loading={loading}
          newPassword={newPassword}
          newPasswordValidate={newPasswordValidate}
          setCode={setCode}
          setCodeValidate={setCodeValidate}
          setConfirmPassword={setConfirmPassword}
          setConfirmPasswordValidate={setConfirmPasswordValidate}
          setNewPassword={setNewPassword}
          setNewPasswordValidate={setNewPasswordValidate}
          userName={userName}
          isTempBlocked={isTempBlocked}
        />
      )}
      <Form.Item>
        {!loading && (
          <Styled.ReturnButton
            type="link"
            onClick={() => {
              setActiveResetPassword(false)
              setCodeSent(false)
            }}>
            Volver
          </Styled.ReturnButton>
        )}
      </Form.Item>
    </>
  )
}

export default RestorePassword
