import React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { ORIENTATION_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

const InductionServices: React.FC = () => (
  <Col span={8}>
    <StyledFormItem label="Inducción Servicios" name="inductionServices">
      <StyledSelectComponent
        mode="multiple"
        placeholder="Inducción Servicios"
        allowClear
        size="large">
        {ORIENTATION_STATUSES
          ? renderMultipleOptions(ORIENTATION_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default InductionServices
