import * as React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { PROFESSIONAL_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

const Accreditation: React.FC = () => (
  <Col span={8}>
    <StyledFormItem
      label="Acreditación (Estado)"
      name="professionalAccreditation">
      <StyledSelectComponent
        maxTagCount={2}
        mode="multiple"
        placeholder="Acreditación"
        size="large">
        {PROFESSIONAL_STATUSES
          ? renderMultipleOptions(PROFESSIONAL_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default Accreditation
