import { LoadingOutlined } from '@ant-design/icons'
import { Avatar as AvatarIcon, Dropdown, Spin } from 'antd'
import styled from 'styled-components'

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const TypeUser = styled.span`
  font-size: 12px;
  color: #343b4c;
  opacity: 0.6;
  letter-spacing: 0px;
  text-align: right;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const Span = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #343b4c;
  opacity: 0.9;
  text-align: right;
  letter-spacing: 0;
`

export const AntdDropdown = styled(Dropdown)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  & > span[aria-label='caret-down'] {
    margin-left: inherit;
    font-size: 12px !important;
    color: #55585f;
  }
`

export const Avatar = styled(AvatarIcon)`
  width: 32px;
  height: 32px;
  border-radius: 50px;
`

export const AntIcon = styled(LoadingOutlined)`
  font-size: 24px;
`

export const LoadingSpinner = styled(Spin)`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  & > span[aria-label='loading'] {
    margin-right: 8px;
    color: #abd838 !important;
  }
  .ant-spin-text {
    color: #abd838 !important;
  }
`
