import { gql } from '@apollo/client'

export const CREATE_UPLOAD_URL = gql`
  mutation($code: Int!, $filename: String!) {
    createUploadUrl(code: $code, filename: $filename)
  }
`

export const CREATE_FETCH_URL = gql`
  mutation($key: String!) {
    createFetchUrl(key: $key)
  }
`
