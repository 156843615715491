import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Progress } from 'antd'

import {
  LoadingContent as StyledSpinContainer,
  Spin as StyledSpin,
  Upload,
} from '../styles'
import { SignatureUploadProps } from '../type'

import { handleChangeUpload, showConfirmModal } from './ConfirmModal'

export const SignatureUpload = ({
  errorUploadsList,
  loadingUploadsList,
  document,
  listType,
  professional,
  uploadListDoc,
  setFileToUpload,
  fetchedFileUrl,
  getUrlForDelete,
  uploadPercent,
  setKindOfAction,
}: SignatureUploadProps) => {
  return (
    <Col span={24}>
      {!errorUploadsList && !loadingUploadsList ? (
        <Upload
          accept="image/png, image/jpeg, image/jpg, application/pdf"
          beforeUpload={() => {
            return false
          }}
          name={document}
          listType={listType}
          onChange={file =>
            handleChangeUpload({
              file,
              professional,
              uploadListDoc,
              listType,
              setFileToUpload,
            })
          }
          fileList={fetchedFileUrl}
          onRemove={() =>
            showConfirmModal({
              document,
              getUrlForDelete,
              uploadListDoc,
              setKindOfAction,
            })
          }
          // onPreview={handlePreview}
          scMargin={false}
          scHidden={fetchedFileUrl.length > 0}>
          {!loadingUploadsList &&
            fetchedFileUrl.length === 0 &&
            uploadPercent === 0 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8, color: '#898989' }}>
                  Subir firma
                </div>
              </div>
            )}
          {uploadPercent > 0 && (
            <div>
              <LoadingOutlined />
              <Progress
                percent={uploadPercent}
                strokeColor={{
                  '0%': '#ABD838',
                  '100%': '#ABDB38',
                }}
                size="small"
              />
            </div>
          )}
        </Upload>
      ) : (
        <StyledSpinContainer>
          <StyledSpin />
        </StyledSpinContainer>
      )}
    </Col>
  )
}
