import { FC } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import esES from 'antd/es/locale/es_ES'
import dayjs from 'dayjs'

import 'typeface-lato'

import { Client } from './ApolloConfig'
import Provider from './Providers'
import Root from './Root'
import { AppRouters } from './Screens'
import { GlobalStyle } from './Styles'

import 'antd/dist/antd.css'

dayjs.locale('es')

const AppSam: FC = () => (
  <ConfigProvider locale={esES}>
    <Provider.Apollo client={Client}>
      <Provider.SessionProvider>
        <Router>
          <GlobalStyle />
          <Route component={Root}>{AppRouters}</Route>
        </Router>
      </Provider.SessionProvider>
    </Provider.Apollo>
  </ConfigProvider>
)

export default AppSam
