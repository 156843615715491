import {
  AutoComplete,
  Avatar as AvatarComponent,
  BackTop as BackTopButton,
  Button,
  Checkbox as CheckboxAnt,
  Col,
  DatePicker as AntDatePicker,
  Divider,
  Form,
  Input as AntInput,
  Modal as AntModal,
  Row,
  RowProps,
  Select as AntSelect,
  Spin as SpinAnt,
  Table as TableAnt,
  Tabs,
  Timeline as TimelineAnt,
  Tooltip as TooltipAnt,
  Upload as UploadAnt,
} from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { DatePickerProps } from 'antd/lib/date-picker'
import styled, { css } from 'styled-components'

interface TitleProps {
  margin?: 0 | 5 | 10 | 15 | 20 | 40 | 50 | number
}

interface AffixProperties {
  $hideOnScroll: boolean
}
interface RowMedicalRecordProps extends RowProps {
  justifyprop?: string
}

interface ButtonHeaderProperties extends ButtonProps {
  color: 'green' | 'default'
}

const { Item } = Form
const { TabPane } = Tabs
const { Option } = AntSelect
const { TextArea } = AntInput
const { Item: ItemTimeLine } = TimelineAnt

export const MenuComponent = styled(Tabs) <AffixProperties>`
  margin: 0px;
  margin-top: 10px;
  background-color: #f9f9f9 !important;
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffff !important;
    .ant-tabs-tab-btn {
      color: #abd838 !important;
    }
  }
  &.ant-tabs {
    .ant-tabs-nav {
      ${props =>
        props && props.$hideOnScroll
          ? css`
              margin-top: 138px !important;
            `
          : css`
              margin-top: calc(6vh + 1.6vh) !important;
            `}
      transition: margin-top 0.3s ease !important;
      position: fixed !important;
      z-index: 1 !important;
    }
  }
  .ant-tabs-tab-btn {
    color: #b9b8b6 !important;
  }
  .ant-tabs-tab {
    margin: 0px 1px !important;
    padding: 16px 29px !important;
    font-size: 16px !important;
  }
  .ant-tabs-nav-list {
    background: #f3f3f3;
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
    background-color: #abd838 !important;
  }
  @media (min-width: 1200px) {
    .ant-tabs-tab {
      padding: 16px 29px !important;
    }
  }
  // @media (min-width: 900px){
  //   .ant-tabs-tab {
  //     padding: 16px 20px !important;
  //   }
  // }
  @media (min-width: 1600px) {
    .ant-tabs-tab {
      padding: 16px 55px !important;
    }
  }
`

export const MenuItem = styled(TabPane)`
  margin: 30px 20px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 230px !important;
  color: black !important;
`

export const Avatar = styled(AvatarComponent)`
  border: 3px solid #abd838;
  margin-left: 30px;
  // margin-top: 30px;
  padding: 3px;
  img {
    border-radius: 50px !important;
  }
`

export const ButtonHistoryPrivileges = styled(Button)`
  top: 50px !important;
  margin-left: 240px !important;
  color: #abd838 !important;
  // position: absolute !important;
`

export const DataHeaderContainer = styled.div`
  margin-left: 0px;
  margin-top: 30px;
  span {
    color: #bbbbb9;
  }
  h1 {
    margin: 0px;
    font-size: 25px;
    font-weight: bold;
    color: #707070;
  }
  h3 {
    margin: 0px;
    color: #2d3f8c;
    font-size: 18px;
  }
  h4 {
    margin: 0px;
    font-size: 20px;
    font-weight: 500;
    color: #707070;
  }
`

export const ButtonHeader = styled(Button)<ButtonHeaderProperties>`
  width: 240px;
  height: 40px;
  margin-top: 5px !important;
  border: 1.5px solid #abd838 !important;
  padding: 2px;
  color: rgba(137, 137, 137, 0.952) !important;
  transition: 500ms ease;
  span {
    font-weight: bold;
  }
  &:hover {
    background: ${props =>
      props && props.color === 'green' ? '#abd838' : '#2d3f8c'} !important;
    border: 1.5px solid rgba(137, 137, 137, 0.952) !important;
    color: #ffff !important;
  }
`

interface ButtonHeaderBlueProps {
  disabled: boolean
}

export const ButtonHeaderBlue = styled(Button)<ButtonHeaderBlueProps>`
  width: 240px;
  height: 40px;
  background: #2d3f8c;
  margin-top: 5px !important;
  color: red !important;
  border: 1.5px solid #2d3f8c;
  padding: 2px;
  transition: all 0.3s !important;
  color: #2d3f8c !important;
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
  &:focus {
    background: #4e63ba !important;
  }

  &:active {
    background: #4e63ba !important;
  }
  ${props =>
    props.disabled &&
    css`
      span {
        color: #b9b8b6 !important;
      }
      &:hover {
        border: 1px solid tomato !important;
        span {
          color: tomato !important;
        }
      }
    `}
`

export const ButtonHeaderAction = styled(Button)`
  width: 240px;
  height: 40px;
  background: #fff;
  margin-top: 5px !important;
  color: red !important;
  border: 1.5px solid #2d3f8c;
  padding: 2px;
  color: #2d3f8c !important;
  span {
    font-weight: bold;
    color: #2d3f8c !important;
  }

  &:hover {
    background: #4e63ba;
    span {
      font-weight: bold;
      color: #fff !important;
    }
  }
  &:focus {
    background: #4e63ba !important;
    span {
      font-weight: bold;
      color: #fff !important;
    }
  }

  &:active {
    background: #4e63ba !important;
  }
`

type ButtonStatusProps = {
  color?: string
}

export const ButtonStatus = styled(Button)<ButtonStatusProps>`
  width: auto;
  height: 32px;
  margin-top: 10px;
  background: ${props => props.color} 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: ${props => props.color};
    color: #2d3f8c !important;
  }
  &:focus {
    background: ${props => props.color};
    color: #2d3f8c !important;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 30px !important;
`

// Data Personals

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 140px auto;
`

interface InputComponentProps {
  disabled?: boolean
}

export const InputComponent = styled(AntInput)<InputComponentProps>`
  margin-bottom: -10px !important;
  color: #898989 !important;
  font-size: 14px !important;
  width: 91%;
  height: 40px;
  border-radius: 6px !important;
  font-weight: 400 !important;
  background-color: #f5f5f5 !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
  ${props =>
    props.disabled &&
    css`
      &:hover {
        border-color: tomato !important;
      }
    `}
`

export const Title = styled.h3<TitleProps>`
  color: #abd838 !important;
  font-weight: bold !important;
  margin-top: 20px !important;
  ${props =>
    props && props.margin && props.margin >= 0
      ? css`
          margin-top: ${props.margin}px !important;
        `
      : !props &&
        css`
          margin-top: 50px;
        `}
  &:hover {
    border-color: #abd838 !important;
  }
`

export const DividerLine = styled(Divider)`
  background: lightgray;
  margin-top: 10px;
  margin-right: 10px;
  &.ant-divider-horizontal {
    width: 50% !important;
    min-width: 96% !important;
  }
`

export const DividerTable = styled(Divider)`
  background: #ffff;
  margin-top: 10px;
  margin-right: 10px;
  &.ant-divider-horizontal {
    width: 50% !important;
    min-width: 96% !important;
  }
`

export const DividerLineProfessional = styled(Divider)`
  background: lightgray;
  margin-top: 10px;
  margin-right: 10px;
  &.ant-divider-horizontal {
    width: 50% !important;
    min-width: 97% !important;
  }
`

export const FormItem = styled(Item)`
  .ant-form-item-label > label {
    color: #4e4e4f !important;
    font-weight: 600 !important;
  }
`

export const SelectComponent = styled(AntSelect)`
  width: 91% !important;
  .ant-select-selector {
    color: #898989 !important;
    border-radius: 6px !important;
    background-color: #f5f5f5 !important;
    font-weight: 400 !important;
    font-size: 14px;
    &:hover {
      border-color: #abd838 !important;
    }
  }
  .ant-select-focused {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select-open {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select {
    &::selection {
      border-color: #abd838 !important;
    }
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  ${props =>
      props.disabled
          ? css`
            .ant-select-selector {
              &:focus {
                border-color: #ff5b5b !important;
              }

              &:hover {
                border-color: #ff5b5b !important;
              }
            }
        `
          : css`
            .ant-select-selector {
              &:focus {
                border-color: #abd838 !important;
              }

              &:hover {
                border-color: #abd838 !important;
              }
            }
  `}
`

export const Options = styled(Option)`
  .ant-select-selection-search {
    font-weight: regular !important;
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
`

export const RowMedicalRecord = styled(Row)<RowMedicalRecordProps>`
  ${props =>
    props.justifyprop &&
    css`
      display: flex;
      justify-content: ${props.justifyprop} !important;
    `}
  margin: 5px 10px;
`

export const Birthday = styled(AntDatePicker)`
  width: 91% !important;
  .ant-picker-input > input {
    color: #898989 !important;
    cursor: pointer;
  }
  background-color: #f5f5f5 !important;
  height: 40px;
  border-radius: 6px !important;
  &:hover {
    border-color: #abd838 !important;
  }
  &:focus {
    border-color: #abd838 !important;
  }
`

// Accreditation

export const UploadContainer = styled.div`
  width: 91% !important;
  border: 1px solid #d3d3d3;
  padding: 3px;
  border-radius: 6px;
  background-color: #f5f5f5;
`

export const UploadContainerLegal = styled.div`
  width: 88% !important;
  border: 1px solid #d3d3d3;
  padding: 3px;
  border-radius: 6px;
  background-color: #f5f5f5;
`

export const TableCategory: React.FC<Record<string, any>> = styled(TableAnt)`
  .ant-table-thead > tr > th {
    color: #abd838 !important;
    background-color: #f5f5f5 !important;

    font-weight: bold !important;
    font-size: 1.17em;
  }
  .ant-table-tbody > tr > td {
    font-weight: 500 !important;
    color: #898989 !important;
    background-color: #f5f5f5 !important;
  }
  .ant-pagination-item-active {
    border-color: #abd838 !important;
    a {
      color: #abd838 !important;
    }
  }
  a:hover {
    color: #abd838 !important;
    border-color: #abd838 !important;
  }
  .ant-table-body {
    overflow-y: unset !important;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #abd838 !important;
  }
`

export const Upload = styled(UploadAnt)`
  .ant-upload-list-item-name {
    color: #898989 !important;
  }
  .ant-upload-text-icon > .anticon {
    color: #898989 !important;
  }
  .ant-upload-list-item {
    margin-left: 35px !important;
    margin-top: -26px !important;
  }
  .ant-btn {
    &:hover {
      border-color: #abd838 !important;
      color: #abd838 !important;
    }
    &:focus {
      border-color: #abd838 !important;
      color: #abd838 !important;
    }
  }
`

export const Select = styled(AntSelect)`
  width: 91% !important;
  background-color: #f5f5f5 !important;
  .ant-select-selector {
    color: #898989 !important;
    border-radius: 6px !important;
    font-weight: regular !important;
    &:hover {
      border-color: #abd838 !important;
    }
  }
`

export const Input = styled(AntInput)`
  width: 91% !important;
  color: #898989;
  background-color: #f5f5f5 !important;
  height: 40px;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
  ${props =>
      props.disabled
          ? css`
          &:focus {
            border-color: #ff5b5b !important;
          }
          &:hover {
            border-color: #ff5b5b !important;
          }
        `
          : css`
          &:focus {
            border-color: #abd838 !important;
          }
          &:hover {
            border-color: #abd838 !important;
          }
  `}
`

export const InputProfessional = styled(AntInput)<{ disabled?: boolean }>`
  width: 93% !important;
  color: #898989;
  background-color: #f5f5f5 !important;
  height: 40px;
  border-radius: 6px !important;
  ${props =>
    props.disabled
      ? css`
          &:focus {
            border-color: #ff5b5b !important;
          }
          &:hover {
            border-color: #ff5b5b !important;
          }
        `
      : css`
          &:focus {
            border-color: #abd838 !important;
          }
          &:hover {
            border-color: #abd838 !important;
          }
        `}
`

export const InputLarge = styled(AntInput)`
  width: 98% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputLargeSub = styled(AntInput)`
  width: 97% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputSub = styled(AntInput)`
  width: 95.5% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const ButtonDeleteSub = styled(Button)`
  width: 91% !important;
  color: #898989;
  background-color: #e3e3e3 !important;
  height: 40px;
  border-radius: 6px !important;
  &&.ant-btn:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #ff5b5b !important;
    color: #ff5b5b;
    background: none;
  }
`

export const ButtonDeleteTeaching = styled(Button)`
  width: 91% !important;
  color: #898989;
  margin-top: 28px;
  background-color: #e3e3e3 !important;
  height: 40px;
  border-radius: 6px !important;
  &&.ant-btn:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #ff5b5b !important;
    color: #ff5b5b;
    background: none;
  }
`

export const NoDataHistoryContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10vh;
`

export const NoData = styled.h1`
  color: #b9b8b6;
  font-size: 22px;
`

export const NoDataHistory = styled.h1`
  color: #b9b8b6;
  font-size: 22px;
  margin-top: -65px;
`

export const SubSpecialtyCountainer = styled.div`
  background-color: #f0f0f0 !important;
  padding: 10px;
  padding-left: 20px;
  margin-right: 40px;
  padding-right: 0px;
`

export const DatePicker = styled(AntDatePicker)<DatePickerProps>`
  width: 91% !important;
  height: 40px;
  background-color: #f5f5f5 !important;
  .ant-picker-input > input {
    color: #898989 !important;
    cursor: pointer;
  }
  border-radius: 6px !important;
  &:hover {
    border-color: #abd838 !important;
  }
  &:focus {
    border-color: #abd838 !important;
  }
  ${props =>
      props.disabled
          ? css`
            .ant-picker-input > input {
              cursor: not-allowed;
            }
          &:focus {
            border-color: #ff5b5b !important;
          }
          &:hover {
            border-color: #ff5b5b !important;
          }
        `
          : css`
          &:focus {
            border-color: #abd838 !important;
          }
          &:hover {
            border-color: #abd838 !important;
          }
  `}
`

export const DatePickerRadiological = styled(AntDatePicker)`
  width: 84% !important;
  height: 40px;
  background-color: #f5f5f5 !important;
  .ant-picker-input > input {
    color: #898989 !important;
    cursor: pointer;
    font-size: 12.5px;
  }
  border-radius: 6px !important;
  &:hover {
    border-color: #abd838 !important;
  }
  &:focus {
    border-color: #abd838 !important;
  }
`

export const SpinBar = styled(SpinAnt)`
  &.ant-spin-lg > .ant-spin-dot {
    margin-left: 618px !important;
  }
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
`

export const Spin = styled(SpinAnt)`
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
`

export const TextAreaComponent = styled(TextArea)<InputComponentProps>`
  width: 91% !important;
  color: #898989;
  background-color: #f5f5f5 !important;
  &:hover {
    border-color: #abd838 !important;
  }
  &:focus {
    border-color: #abd838 !important;
  }
  ${props =>
      props.disabled
          ? css`
          &:focus {
            border-color: #ff5b5b !important;
          }
          &:hover {
            border-color: #ff5b5b !important;
          }
        `
          : css`
          &:focus {
            border-color: #abd838 !important;
          }
          &:hover {
            border-color: #abd838 !important;
          }
  `}
`

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto;
`
interface ButtonCategoryProps {
  margin?: 10 | number
}

export const ButtonCategory = styled(Button)<ButtonCategoryProps>`
  border-radius: 25px;
  color: #abd838;
  border-color: #abd838;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 45px;
  ${props =>
    props.margin &&
    css`
      margin-top: ${props.margin}px;
    `}
  &:hover {
    color: #ffff !important;
    background-color: #abd838;
    border-color: #abd838;
  }
  &:focus {
    color: #ffff !important;
    background-color: #abd838;
    border-color: #abd838 !important;
  }
`

export const ButtonTableEdit = styled(Button)`
  border: none;
  background: none;
  &.ant-btn:focus {
    color: #000000 !important;
    background: none;
  }
  &:hover {
    color: #abd838;
    background: none;
  }
`

export const ButtonTableDelete = styled(Button)`
  border: none;
  background: none;
  &.ant-btn:focus {
    color: #000000 !important;
    background: none;
  }
  &:hover {
    color: #ff5b5b;
    background: none;
  }
`

export const TitleModal = styled.h2`
  color: #375ea8;
  font-weight: bold;
  margin: 50px 122px 36px 135px;
`

export const TitleModalStatus = styled.h2`
  color: #375ea8;
  font-weight: bold;
  margin: 21px 227px -33px 53px;
  margin-bottom: 25px;
`

export const TitleModalStatusName = styled.h2`
  color: #abd838;
  font-weight: bold;
  margin: -58px 0px 10px 238px;
  margin-bottom: 25px;
`

export const TitleModalService = styled.h2`
  color: #375ea8;
  font-weight: bold;
  margin: auto !important;
`

export const StyledTitleContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin: 10px 0px 20px 0px !important;
`

export const Modal = styled(AntModal)`
  .ant-modal-root {
    .ant-modal-wrap {
      background-color: red !important;
      overflow: hidden !important;
    }
  }
`

export const TitleModalServiceName = styled.h2`
  color: #abd838;
  font-weight: bold;
  margin: auto auto 30px auto !important;
`

export const ModalContent = styled.div`
  margin: 0px 15px 55px 55px;
`

export const TimelineService = styled(TimelineAnt)`
  .ant-timeline-item-tail {
    border-color: #6f6f6f66 !important;
  }
  .ant-timeline-item {
    margin-left: 55px !important;
  }
  .ant-timeline-item-content {
    color: #b9b8b6 !important;
  }
  &.ant-timeline {
    .ant-timeline-item-label {
      color: #b9b8b6 !important;
    }
  }
  h4 {
    color: #4e4e4f !important;
    margin-bottom: -2px;
    font-size: bold;
  }
`

export const ButtonModal = styled(Button)`
  width: 91%;
  height: 50px;
  background: #2d3f8c;
  margin-top: 5px !important;
  color: red !important;
  border: 1.5px solid #2d3f8c;
  padding: 0px 40px;
  color: #2d3f8c !important;
  .ant-btn {
    border-radius: 25px !important;
  }
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
  &:focus {
    background: #4e63ba !important;
  }
  &:active {
    background: #4e63ba !important;
  }
`

export const Checkbox = styled(CheckboxAnt)`
  &.ant-checkbox-wrapper {
    margin-left: -2px !important;
    margin-top: 15px !important;
  }
  .ant-checkbox + span {
    font-size: 14px !important;
    color: #4e4e4f !important;
    font-weight: normal !important;
  }
  .ant-checkbox-inner {
    border-color: #abd838 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: #abd838 !important;
    border-color: #abd838 !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #abd838 !important;
  }
`

export const ButtonTopbar = styled(Button)`
  &.ant-btn {
    height: 40px !important;
    width: 40px !important;
    color: #fff !important;
    border-radius: 25px !important;
    background: #4d63ba !important;
    font-size: 14px !important;
    margin-right: 100 px important;
  }
`

export const TopbarContainer = styled.div`
  height: 150px;
  border: 1px solid #f9f9f9;
  background-color: #f9f9f9;
`

export const BackTop = styled(BackTopButton)`
  bottom: 90px;
  left: 100px;
`

export const Affix = styled.div<AffixProperties>`
  ${props =>
    props &&
    !props.$hideOnScroll &&
    css`
      top: 0;
      h1 {
        margin-top: -3.5vh;
        margin-left: -2vh;
      }
      h3 {
        display: none !important;
      }
      h4 {
        display: none !important;
      }
      .ant-avatar {
        margin-top: 4vh !important;
        width: 50% !important;
        height: 60% !important;
        line-height: 90px !important;
        font-size: 10px !important;
      }
      .ant-col-xl-15 {
        margin-top: 30px;
      }
    `}
  transition: all 0.5s ease;
  position: fixed;
  z-index: 1;
  @media (min-width: 1200px) {
    .ant-col-xl-15 {
      width: 724px;
    }
  }
  @media (max-width: 1440px) {
    .ant-col-xl-15 {
      width: 681px;
      font-size: 14px !important;
    }
  }
  .bsOfQX {
    color: red !important;
  }
  @media (min-width: 1600px) {
    .ant-col-xl-15 {
      width: 1581px;
    }
  }
  @media (min-width: 1690px) {
    .ant-col-xl-15 {
      width: 1450px;
    }
  }
`

export const Tooltip = styled(TooltipAnt)`
  .ant-tooltip .ant-tooltip-placement-bottom {
    background-color: red !important;
  }
`

export const Email = styled(AutoComplete)`
  width: 91% !important;
  .ant-select-selector {
    height: 40px !important;
    color: #898989 !important;
    border-radius: 6px !important;
    background-color: #f5f5f5 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    &:hover {
      border-color: #abd838 !important;
    }
  }
  .ant-select-focused {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select-open {
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
  .ant-select {
    &::selection {
      border-color: #abd838 !important;
    }
    &:hover {
      border-color: #abd838 !important;
    }
    &:active {
      border-color: #abd838 !important;
    }
    &:hover:active {
      border-color: #abd838 !important;
    }
  }
`

export const OptionAuto = styled(Option)``

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
`

export const ButtonActions = styled(Button)<ButtonHeaderBlueProps>`
  margin: 5px;
  span {
    color: #898989 !important;
  }
  &:hover {
    span {
      color: #abd838 !important;
    }
  }
  ${props =>
      props.disabled &&
      css`
        span {
          color: #e0dede !important;
        }

        &:hover {
          span {
            color: tomato !important;
          }
        }
      `}
`

export const Timeline = styled(TimelineAnt)`
  .ant-timeline-item-tail {
    border-color: #6f6f6f66 !important;
  }
  .ant-timeline-item-content {
    color: #b9b8b6 !important;
  }
  &.ant-timeline {
    .ant-timeline-item-label {
      color: #b9b8b6 !important;
    }
  }
`

export const ButtonShowMore = styled(Button)`
  color: #abd838;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 45px;
  &:hover {
    color: #c2e474 !important;
  }
  &:focus {
    color: #c2e474 !important;
  }
`

type DivShowMoreContainerProps = {
  isShowMore: boolean
  minShow: number
}

export const DivShowMoreContainer = styled.div<DivShowMoreContainerProps>`
  ${props =>
    props.isShowMore &&
    css`
      max-height: ${props.minShow * 150}px;
      overflow: hidden;
    `}
`

export const StyledTimeLineItem = styled(ItemTimeLine)``

export const StyledHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  background: #abd838;
  border-radius: 50%;
`

export const StyledDivFlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledColAlignSelfFlexEnd = styled(Col)`
  align-self: flex-end;
`

export const StyledDivFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledRowMedicalRecordFlexEnd = styled(Row)`
  margin: 5px 10px;
  display: flex;
  justify-content: flex-end;
`

export const ButtonCategorySelfFlexEnd = styled(Button)`
  border-radius: 25px;
  color: #abd838;
  border-color: #abd838;
  font-weight: bold;
  margin-bottom: 10px;
  margin-right: 45px;
  align-self: flex-end;
  &:hover {
    color: #ffff !important;
    background-color: #abd838;
    border-color: #abd838;
  }
  &:focus {
    color: #ffff !important;
    background-color: #abd838;
    border-color: #abd838 !important;
  }
`

export const ButtonHistoryService = styled(Button)`
  top: -5px !important;
  margin-left: 240px !important;
  color: #abd838 !important;
  position: absolute !important;
`

export const ButtonHistoryDepartment = styled(Button)`
  top: -5px !important;
  margin-left: 240px !important;
  color: #abd838 !important;
  margin-bottom: -5px !important;
  position: absolute !important;
`

export const ButtonHistoryInduction = styled(Button)`
  top: 50px !important;
  margin-left: 180px !important;
  color: #abd838 !important;
  //position: absolute !important;
`

export const ButtonHistoryInductionDepartment = styled(Button)`
  top: 50px !important;
  margin-left: 200px !important;
  color: #abd838 !important;
  //position: absolute !important;
`

type StyledColDisplayConditionallyProps = {
  show: boolean
}

export const StyledColDisplayConditionally = styled(
  Col,
)<StyledColDisplayConditionallyProps>`
  ${props =>
    !props.show &&
    css`
      display: none;
    `}
`
