import { Button, Checkbox, Input, Row, Select, Spin as SpinAnt, Table } from 'antd'
import styled from 'styled-components'

const CheckboxGroup = Checkbox.Group

export const SearchRow = styled(Row)`
  margin-top: 15px;
`

export const InputComponent = styled(Input)`
  margin-bottom: 10px !important;
  width: 90%;
`

export const Check = styled(CheckboxGroup)`
  margin: 11px;
  margin-bottom: 10px !important;
`

export const InputSelect = styled(Select)`
  margin-bottom: 10px !important;
  width: 90% !important;
`

export const SearchButton = styled(Button)`
  width: 100% !important;
  background: #2d3f8c !important;
  border: none !important;
  &:hover {
    background: #4f6ad6 !important;
  }
`

export const SearchResult = styled.div`
  margin: 25px 25px;
`

export const Container = styled.div`
  background: white;
  margin: 25px 25px;
  padding: 15px 0px;
`

export const SearchTable = styled(Table)`
  cursor: pointer;
    tr:nth-child(2n) {
    :hover {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    td {
      background-color: #e6e6e6 !important;
    }
  }
  tr:nth-child(2n+1) {
    :hover {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  thead {
    background-color: #f1f1f1;
  }
  thead tr th {
    color: #2D3F8C;
    font-weight: bold
  }
  .ant-table-tbody > tr > td {
    background: #f2f2f2;
  }
    .ant-pagination-item-active {
    border-color: #abd838 !important;
    a {
      color: #abd838 !important;
    }
  }
  a:hover {
    color: #abd838 !important;
    border-color: #abd838 !important;
  }
  &:hover {
    a {
      border-color: #abd838 !important;
    }
  }
  .ant-table-filter-trigger.active {
    color: #abd838 !important;
  } 
`

export const Spin = styled(SpinAnt)`
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
  .ant-spin-dot {
    font-size: 90px;
  }
`

export const SearchLabel = styled.span`
  color: #2D3F8C;
  font-weight: bold;
  margin-left: 20px;
`
