import { FC } from 'react'
import { HeaderDetails } from 'App/GlobalComponents/ReportDetails'

import { OrientationInstitutionalDetailsContent } from './OrientationInstitutionalDetailsContent'


export const InstitutionalOrientationDetails: FC = () => {
    return (
        <div>
            <HeaderDetails />
            <OrientationInstitutionalDetailsContent
                title="Orientación Instituticional"
                subtitle="Personal Médico Activo actualmente"
            />
        </div>
    )
}
