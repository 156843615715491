import React, { useContext } from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
  StyledSelectOptions,
} from 'App/GlobalComponents/styles'
import { filterOptionsFunction, toPascalCase } from 'App/Helpers/utils'
import { AppStateContext } from 'App/Providers/AppState'
import isEmpty from 'lodash/isEmpty'

import { OptionData } from '../../../../types'

const Specialty: React.FC = () => {
  const { specialties } = useContext(AppStateContext)
  const renderOptionsServices = () =>
    !isEmpty(specialties)
      ? specialties.map((service: OptionData) => (
          <StyledSelectOptions key={service._id} value={`${service._id}`}>
            {toPascalCase(service.name)}
          </StyledSelectOptions>
        ))
      : null

  return (
    <Col span={8}>
      <StyledFormItem label="Especialidad" name="specialties">
        <StyledSelectComponent
          maxTagCount={2}
          mode="multiple"
          placeholder="Especialidad"
          size="large"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => filterOptionsFunction(input, option)}>
          {renderOptionsServices()}
        </StyledSelectComponent>
      </StyledFormItem>
    </Col>
  )
}

export default Specialty
