import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallBackSpinner } from 'App/GlobalComponents/UIElements/FallBackSpinner'
import Amplify from 'aws-amplify'

import awsConfig from '../AmplifyConfig/config'

import LoginScreen from './LoginScreen/LoginScreen'
import { CreateReporting } from './Reporting/components/CreateReporting'
import { DepartmentReportingDetails } from './Reporting/components/MedicalCensusReporting/details/departments'
import { ServicesCensusReportingDetails } from './Reporting/components/MedicalCensusReporting/details/services'
import { InstitutionalOrientationDetails } from './Reporting/components/OrientationInductionReporting/details'
import { DepartmentsPrivilegesDetails } from './Reporting/components/PrivilegesReporting/details/departments'
import { ServicesPrivilegesDetails } from './Reporting/components/PrivilegesReporting/details/services'
import { AccreditedProfessionals } from './AccreditedProfessionals'
import HomeScreen from './HomeScreen'
import { SearchScreen } from './SearchScreen'

const MedicalRecord = React.lazy(() => import('App/Screens/MedicalRecord'))
const NewProfessional = React.lazy(() => import('App/Screens/NewProfessional'))
const Reporting = React.lazy(() => import('App/Screens/Reporting'))

Amplify.configure(awsConfig)

const AppLayoutRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(matchProps: any) => (
      <HomeScreen>
        <Component {...matchProps} />
      </HomeScreen>
    )}
  />
)

export const AppRouters: React.FC = () => (
  <React.Suspense
    fallback={<FallBackSpinner text="Cargando..." size="large" />}>
    <Switch>
      <Route
        path="/login"
        component={(props: any) => {
          return <LoginScreen label="Profesionales" path="/home" {...props} />
        }}
      />
      <AppLayoutRoute path="/home" component={AccreditedProfessionals} />
      <AppLayoutRoute exact path="/busqueda" component={SearchScreen} />
      <AppLayoutRoute path="/profesional/:_id" component={MedicalRecord} />
      <AppLayoutRoute path="/reporteria" component={Reporting} />
      <AppLayoutRoute path="/crear-nuevo-reporte" component={CreateReporting} />
      <AppLayoutRoute path="/censo-medico-departamentos-detalles" component={DepartmentReportingDetails} />
      <AppLayoutRoute path="/censo-medico-servicios-detalles" component={ServicesCensusReportingDetails} />
      <AppLayoutRoute path="/privilegios-servicios-detalles" component={ServicesPrivilegesDetails} />
      <AppLayoutRoute path="/privilegios-departamentos-detalles" component={DepartmentsPrivilegesDetails} />
      <AppLayoutRoute path="/orientacion-institucional-detalles" component={InstitutionalOrientationDetails} />
      <Route path="/ingreso-profesional" component={NewProfessional} />
      <Route path="/" component={() => <Redirect to={'/home'} />} />
      <Route component={() => <h1>404</h1>} />
    </Switch>
  </React.Suspense>
)
