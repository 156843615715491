import React, { useContext } from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
  StyledSelectOptions,
} from 'App/GlobalComponents/styles'
import { filterOptionsFunction, toPascalCase } from 'App/Helpers/utils'
import { AppStateContext } from 'App/Providers/AppState'
import { INameValueId } from 'App/Types/GlobalTypes'
import isEmpty from 'lodash/isEmpty'

const Services: React.FC = () => {
  const { services } = useContext(AppStateContext)
  const renderOptionsServices = () =>
    !isEmpty(services)
      ? services.map((service: INameValueId) => (
          <StyledSelectOptions key={service._id} value={`${service._id}`}>
            {toPascalCase(service.name)}
          </StyledSelectOptions>
        ))
      : null

  return (
    <Col span={8}>
      <StyledFormItem label="Servicios" name="services">
        <StyledSelectComponent
          maxTagCount={2}
          mode="multiple"
          placeholder="Servicios"
          allowClear
          size="large"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => filterOptionsFunction(input, option)}>
          {renderOptionsServices()}
        </StyledSelectComponent>
      </StyledFormItem>
    </Col>
  )
}

export default Services
