import { FC, useState } from 'react'
import { StyledDashboardContainerFather, StyledRow } from 'App/GlobalComponents/styles'

import { StyledDividerReporting } from '../../styles'

import { CreateReportButton } from './CreateReportButton'
import { initialValues } from './data'
import CreateReport from './form'
import GeneralInformation from './GeneralInformation'
import HeaderCreateReporting from './HeaderCreateReporting'
import { FilterFormItems } from './types'

export const CreateReporting: FC = () => {
  const [formFilter, setFormFilter] = useState<FilterFormItems>(initialValues)

  return (
    <StyledDashboardContainerFather>
      <HeaderCreateReporting />
      <CreateReport setFormFilter={setFormFilter} />
      <h1>Información General</h1>
      <StyledDividerReporting />
      <StyledRow>
        <GeneralInformation />
        <CreateReportButton formFilter={formFilter} />
      </StyledRow>
    </StyledDashboardContainerFather>
  )
}
