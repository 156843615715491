import styled from 'styled-components'


export const StyledHeaderCreateReportingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 5px 5px -30px;
`

export const StyledTitleHeaderCreateReporting = styled.div`
  display: flex;
  h1 {
    color: #2d3f8c;
    cursor: pointer;
    font-size: 30px;
    margin: 0 0 0 30px;
  }
  h4 {
    margin: 60px 0 0 -315px;
    color: #ABD838;
    font-weight: bold;
  }
  .anticon {
    color: #2d3f8c;
    font-weight: bold;
    cursor: pointer;
    font-size: 25px;
    margin: 15px 0 0 35px;
  }
`
