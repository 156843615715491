import { gql } from '@apollo/client'

export const GET_PROFESSIONAL_SEARCH = gql`
  query SearchProfessionals(
    $pagination: PaginationInput
    $input: SearchInput!
  ) {
    searchProfessionals(pagination: $pagination, input: $input) {
      _id
      firstName
      firstSurname
      secondSurname
      rut
      services {
        service
      }
      department {
        department
      }
      categories {
                category
            }
            status
            code
        }
    }
`
