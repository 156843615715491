import React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { ORIENTATION_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

const InstitutionalOrientation: React.FC = () => (
  <Col span={8}>
    <StyledFormItem
      label="Orientación Institucional"
      name="institutionalOrientation">
      <StyledSelectComponent
        mode="multiple"
        placeholder="Orientación Institucional"
        allowClear
        size="large">
        {ORIENTATION_STATUSES
          ? renderMultipleOptions(ORIENTATION_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default InstitutionalOrientation
