import { useContext } from 'react'
import { SpinProps } from 'antd/lib/spin'
import { AppStateContext } from 'App/Providers/AppState'

import * as Styled from '../styles'

interface SpinnerProperties extends SpinProps {
  children: any
  description?: string
  color?: string
  size?: 'small' | 'default' | 'large'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
/**
 *  Spinner Component
 *
 * @param {SpinnerProperties} {
 *   children,
 *   description = '',
 *   color = '#abd838'
 *   size: 'small' | 'default' | 'large'
 * }
 * @return {*}  {JSX.Element}
 */
export const Spinner = ({
  children,
  description = '',
  color = '#abd838',
  size = 'large',
}: SpinnerProperties): JSX.Element => {
  const { loading } = useContext(AppStateContext)

  return (
    <Styled.Spin
      spinning={loading}
      delay={500}
      tip={description}
      color={color}
      size={size}>
      {children}
    </Styled.Spin>
  )
}
