import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  StyledDashboardContainerFather,
  StyledHeaderDetails,
  StyledTitleHeaderDetails
} from 'App/GlobalComponents/styles'

export const HeaderMedicalCensusDepartmentsDetails: FC = () => {
  const history = useHistory()

  return (
    <StyledDashboardContainerFather>
      <StyledHeaderDetails>
        <StyledTitleHeaderDetails>
          <ArrowLeftOutlined onClick={() => history.push('/reporteria')} />
          <h1 onClick={() => history.push('/reporteria')}>
            Volver a Dashboard
          </h1>
        </StyledTitleHeaderDetails>
      </StyledHeaderDetails>
    </StyledDashboardContainerFather>
  )
}
