import { gql } from '@apollo/client'

export const GET_ALL_DEPARTMENTS = gql`
  query allDepartments($pagination: PaginationInput) {
    allDepartments (pagination: $pagination) {
      _id
      name
    }
  }
`

export const GET_ALL_CENTERS = gql`
  query allCenters {
    allCenters {
      _id
      name
    }
  }
`

export const GET_ALL_SERVICES = gql`
  query allServices {
    allServices {
      _id
      name
    }
  }
`

export const GET_ALL_WORKPLACES = gql`
  query allWorkPlaces {
    allWorkPlaces {
      _id
      name
    }
  }
`

export const GET_ALL_SUBDEPARTMENTS = gql`
  query allSubDepartments {
    allSubDepartments {
      _id
      name
    }
  }
`

export const GET_ALL_CATEGORIES = gql`
  query allCategories {
    allCategories{
      _id
      name
    }
  }
`

export const GET_ALL_POSITIONS = gql`  
  query allPositions {
    allPositions {
      _id
      name
    }
  }
`
