import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 10%;
    padding-bottom: 10%;
`
