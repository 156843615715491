import { Alert, Form, Input } from 'antd'

import { ILoginUser } from '../../../../Types/LoginScreenTypes'
import { FormItem, LinkForgotPassword, LoginButton } from '../../styles'

const LoginUser = ({
  activeResetPassword,
  loading,
  errorMessage,
  onFinish,
  onFinishFailed,
  setActiveResetPassword,
  setUserName
}: ILoginUser): any => {
  const handleActiveResetPassword = () => {
    setActiveResetPassword(!activeResetPassword)
    console.log('activeResetPassword')
  }

  const homologueLoginMessages = (str: string) => {
    let display = ''

    switch (typeof str === 'string') {
      case str.includes('Incorrect username or password'):
        display =
          'Hubo un error, el usuario y/o contraseña no son correctos. Inténtelo de nuevo'
        break
      case str.includes('User does not exist'):
        display = 'El usuario que ingresó no se encuentra registrado en el sistema. Para mayor información contactar sebastian.bobadilla@rokketlabs.com'
        break
      default:
        display = 'Hubo un error, Inténtelo de nuevo.'
    }

    return display
  }

  return (
    <>
      {errorMessage && (
        <div className="loginAlerts">
          <Alert
            message={homologueLoginMessages(errorMessage)}
            type="error"
            showIcon
          />
        </div>
      )}
      <Form
        layout="vertical"
        name="loginUser"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <FormItem
          name="username"
          label="Email"
          rules={[
            {
              type: 'email',
              message: '¡Ingrese un correo válido!',
            },
            {
              required: true,
              message: '¡El correo es requerido!',
            },
          ]}>
          <Input
            name="email"
            placeholder="Ingrese correo"
            onChange={e => {
              setUserName(e.target.value)
            }}
            allowClear
          />
        </FormItem>

        <FormItem
          style={{ marginBottom: 0 }}
          name="password"
          label="Contraseña"
          rules={[
            {
              required: true,
              message: '¡La contraseña es requerida!',
            },
          ]}>
          <Input.Password placeholder="Ingrese contraseña" />
        </FormItem>
        <FormItem style={{ marginTop: 0 }}>
          <LinkForgotPassword type="link" onClick={handleActiveResetPassword}>
            ¿Olvidaste tu contraseña?
          </LinkForgotPassword>
        </FormItem>
        <FormItem>
          <LoginButton
            size="large"
            type="ghost"
            block
            htmlType="submit"
            loading={loading}>
            Ingresar a SAM
          </LoginButton>
        </FormItem>
      </Form>
    </>
  )
}

export default LoginUser
