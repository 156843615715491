import React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { PRIVILEGES_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

const LiabilityInsurance: React.FC = () => (
  <Col span={8}>
    <StyledFormItem label="Seguro de Responsabilidad" name="liabilityInsurance">
      <StyledSelectComponent
        mode="multiple"
        placeholder="Seguro de Responsabilidad"
        allowClear
        size="large">
        {PRIVILEGES_STATUSES
          ? renderMultipleOptions(PRIVILEGES_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default LiabilityInsurance
