import { Spin as SpinAnt } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import styled, { css } from 'styled-components'

interface ISpinProperties extends SpinProps {
  color?: string
}

export const Spin = styled(SpinAnt)<ISpinProperties>`
  ${props =>
    props.color
      ? css`
          .ant-spin-text {
            color: ${props.color} !important;
            letter-spacing: 1px;
          }
          .ant-spin-dot-item {
            background-color: ${props.color} !important;
          }
        `
      : css`
          .ant-spin-text {
            color: #abd838 !important;
            letter-spacing: 1px;
          }
          .ant-spin-dot-item {
            background-color: #abd838 !important;
          }
        `};
`
