import React from 'react'
import { Collapse } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

const { Panel } = Collapse

// import { StyledDividerReporting } from '../../../styles'

import { initialGeneralInfoCheckboxes } from './date'
import {
  StyledCheckboxGeneralInformation,
  StyledCustomReportContainer,
  StyledGeneralInformationContainer,
  StyledListCheckbox,
  StyledSwitch,
  StyledSwitchContainer,
} from './styles'
import { CheckBoxGeneralInfoType } from './types'

const GeneralInformation: React.FC = () => {
  const [switchState, setSwitch] = React.useState<boolean>(false)
  const [indeterminate, setIndeterminate] = React.useState(false)
  const [checkAll, setCheckAll] = React.useState(false)
  const [checkboxes, setCheckboxes] = React.useState<CheckBoxGeneralInfoType[]>(
    initialGeneralInfoCheckboxes,
  )
  const handleSwitchChange = (checked: boolean) => setSwitch(checked)
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setIndeterminate(false)
    setCheckAll(e.target.checked)

    const modifiedCheckboxes = [...checkboxes]

    setCheckboxes(modifiedCheckboxes)
  }
  const checkboxPanel = () => {
    return (
      <StyledCheckboxGeneralInformation
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}>
        Datos Personales
      </StyledCheckboxGeneralInformation>
    )
  }

  return (
    <StyledGeneralInformationContainer>
      <StyledSwitchContainer switchView={switchState}>
        <StyledSwitch disabled onChange={handleSwitchChange} />
        <h4>Reporte Personalizado</h4>
      </StyledSwitchContainer>
      {switchState ? (
        <StyledCustomReportContainer>
          <StyledCheckboxGeneralInformation
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}>
            Seleccionar todos los campos para el reporte
          </StyledCheckboxGeneralInformation>
          <Collapse expandIconPosition="right">
            <Panel header={checkboxPanel()} key="1">
              {checkboxes &&
                checkboxes.map(h => (
                  <StyledListCheckbox key={h.name}>
                    <StyledCheckboxGeneralInformation>
                      {h.title}
                    </StyledCheckboxGeneralInformation>
                  </StyledListCheckbox>
                ))}
            </Panel>
          </Collapse>
        </StyledCustomReportContainer>
      ) : (
        ''
      )}
    </StyledGeneralInformationContainer>
  )
}

export default GeneralInformation
