import { useEffect, useState } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { CaretDownOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Menu, Spin } from 'antd'
import { eachWord, ucWords } from 'App/Helpers/utils'
import { Auth } from 'aws-amplify'

import { LogOutIcon } from '../../styles'

import { ModalComponents } from './../../../../GlobalComponents'
import {
  AntdDropdown,
  AntIcon,
  Avatar,
  LoadingSpinner,
  Span,
  TypeUser,
  UserInfo,
} from './styles'

const LogOutUser = ({ history, session, loading: loadingSession }: any) => {
  const location = useLocation()
  const [loadingLogOut, setLoadingLogOut] = useState(false)
  const [currentSession, setCurrentSession] = useState({
    firstName: '',
    lastName: '',
    avatar: '',
    _id: '',
    role: '',
  })

  useEffect(() => {
    typeof session !== undefined && setCurrentSession(session)
  }, [session])

  const handleLogOut = (): void => {
    setLoadingLogOut(true)
    Auth.signOut({ global: true })
      .then(() => {
        setLoadingLogOut(false)
        history.push('/login')
      })
      .catch(() => {
        setLoadingLogOut(false)
        history.push('/login')
      })
  }

  const confirmModal = () => {
    ModalComponents.confirmPrompt({
      title: '¿Desea Salir?',
      okText: 'Salir',
      cancelText: 'Cancelar',
      width: '260px',
      onOk: handleLogOut,
    })
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={confirmModal}>
        {loadingLogOut ? (
          <div>
            <Spin />
          </div>
        ) : (
          <>
            <PoweroffOutlined /> Cerrar Sesión
          </>
        )}
      </Menu.Item>
    </Menu>
  )

  if (location.pathname.startsWith('/login')) return null

  return (
    <>
      <AntdDropdown trigger={['click']} overlay={menu}>
        <LogOutIcon onClick={e => e.preventDefault()}>
          {loadingSession ? (
            <LoadingSpinner
              indicator={<AntIcon spin />}
              delay={200}
              size={'small'}
              tip={'Cargando...'}
            />
          ) : (
            <UserInfo>
              <Span>
                {currentSession && currentSession.firstName
                  ? ucWords(currentSession.firstName)
                  : ''}{' '}
                {currentSession && currentSession.lastName
                  ? ucWords(currentSession.lastName)
                  : ''}
              </Span>
              <TypeUser>{`Perfil ${
                currentSession ? eachWord(currentSession.role) : 'Usuario'
              }`}</TypeUser>
            </UserInfo>
          )}
          <CaretDownOutlined />
          <Avatar
            className="avatar-styles"
            size={40}
            src={
              (!loadingSession && currentSession && currentSession.avatar) ||
              'https://www.gravatar.com/avatar/3c58cbad936e4dbd5350510cf27146b2?d=mp&s=200'
            }
          />
        </LogOutIcon>
      </AntdDropdown>
    </>
  )
}

export default withRouter(LogOutUser)
