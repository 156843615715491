import * as Styled from './../styles'
import { Div } from './styles'

interface FallBackSpinnerProps {
  text?: string
  size?: 'small' | 'default' | 'large'
}

export const FallBackSpinner = ({
  text = 'Cargando...',
  size = 'large',
}: FallBackSpinnerProps): JSX.Element => (
  <Div>
    <Styled.Spin
      tip={text}
      size={size}
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}
    />
  </Div>
)
