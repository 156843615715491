import moment from 'moment'

import * as Styled from './../styles'

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']

interface DatePickerProperties {
  name?: string | undefined
  disabled?: boolean | undefined
  inputReadOnly?: boolean
  showline?: boolean
  format?: string[] | any
  defaultValue?: moment.MomentInput | any
  onChange?: any
  placeholder?: string | undefined
  autoComplete?: string | undefined
  value?: any
  width?: 'default' | 'small' | 'medium'
  fontSize?: 'default' | 'small' | 'medium'
}

/**
 * DatePicker
 *
 * @param {DatePickerProperties} {
 *   autoComplete = 'off',
 *   defaultValue,
 *   disabled = false,
 *   format = dateFormatList,
 *   inputReadOnly = true,
 *   name = '',
 *   onChange,
 *   placeholder,
 *   width = { 'default' | 'small' | 'medium' }
 *   fontSize = { 'default' | 'small' | 'medium' }
 * }
 * @return {*}  {JSX.Element}
 */
export const DatePicker = ({
  autoComplete = 'off',
  defaultValue,
  disabled = false,
  format = dateFormatList,
  inputReadOnly = true,
  name = '',
  onChange,
  showline,
  placeholder,
  value = undefined,
  width = 'default',
  fontSize = 'default'
}: DatePickerProperties): JSX.Element => (
  <Styled.DatePicker
    autoComplete={autoComplete}
    defaultValue={defaultValue}
    disabled={disabled}
    format={format}
    inputReadOnly={inputReadOnly}
    showline={showline}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    width={width}
    fontSize={fontSize}
  />
)

export { default as CustomDatePicker } from './CustomDatePicker'
export { default as CustomRangePicker } from './CustomRangePicker'

