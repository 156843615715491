import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
 * {
      font-family: 'Lato', normal;
      :root {
        --antd-wave-shadow-color:  #abd838 !important;
      }
   }
`
