import { Dispatch, FC, SetStateAction } from 'react'
import { Form } from 'antd'
import { StyledRow } from 'App/GlobalComponents/styles'

import { initialValues } from '../data'
import { FilterFormItems } from '../types'

import Accreditation from './input/Accreditation'
import Categories from './input/Categories'
import Departments from './input/Departments'
import InductionDepartment from './input/InductionDepartment'
import InductionServices from './input/InductionServices'
import InfectionCourses from './input/InfectionCourses'
import InstitutionalOrientation from './input/InstitutionalOrientation'
import LiabilityInsurance from './input/LiabilityInsurance'
import PrivilegeDepartment from './input/PrivilegeDepartment'
import PrivilegeServices from './input/PrivilegeServices'
import ResuscitationCourses from './input/ResuscitationCourses'
import SedationCourses from './input/SedationCourses'
import Services from './input/Services'
import Specialty from './input/Specialty'
import Vaccine from './input/Vaccine'

type CreateReportProps = {
  setFormFilter: Dispatch<SetStateAction<FilterFormItems>>
}

const CreateReport: FC<CreateReportProps> = ({ setFormFilter }) => {
  const [form] = Form.useForm()

  const handleValuesChange = (_value: unknown, items: FilterFormItems) =>
    setFormFilter(items)

  return (
    <Form
      name="create-report-form"
      layout="vertical"
      initialValues={initialValues}
      form={form}
      onValuesChange={handleValuesChange}>
      <StyledRow>
        <Departments />
        <Services />
        <Categories />
      </StyledRow>
      <StyledRow>
        <Accreditation />
        <Specialty />
        <PrivilegeDepartment />
      </StyledRow>
      <StyledRow>
        <PrivilegeServices />
        <InstitutionalOrientation />
        <InductionDepartment />
      </StyledRow>
      <StyledRow>
        <InductionServices />
        <Vaccine />
        <LiabilityInsurance />
      </StyledRow>
      <StyledRow>
        <ResuscitationCourses />
        <InfectionCourses />
        <SedationCourses />
      </StyledRow>
    </Form>
  )
}

export default CreateReport
