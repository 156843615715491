import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col } from 'antd'
import { eachWord, homologueStatus, toPascalCase } from 'App/Helpers/utils'
import { AppStateContext } from 'App/Providers/AppState'
import {
  INameValueId,
  IProfessional,
  ProfessionalsData,
} from 'App/Types/GlobalTypes'
import {
  Category,
  ProfessionalServicesInterface,
} from 'App/Types/ProfessionalTypes'

import { Queries } from '../../Graphql'

import { columns } from './helper'
import {
  ButtonContainerFile,
  ButtonHeaderBlueFile,
  ColFile,
  Divider,
  HeaderContainerFile,
  LoadingContent,
  Postulation,
  RowFile,
  Spin,
  // StyledSpanPointer,
  Table,
} from './styles'
import { AccreditedColumnsType } from './types'

export const AccreditedProfessionals: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const [professionals, setProfessionals] = React.useState<IProfessional[]>()
  const { categories, services, departments } = React.useContext(
    AppStateContext,
  )
  const { loading } = useQuery(Queries.GET_ALL_PROFESSIONALS, {
    onCompleted: data =>
      data && data.allProfessionals && setProfessionals(data.allProfessionals),
  })
  const [professionalData, setProfessionalData] = React.useState<
    ProfessionalsData[]
  >([])

  React.useEffect(() => {
    professionals &&
      services &&
      departments &&
      categories &&
      setProfessionalData(
        professionals.map((el: IProfessional) => {
          const servicesNames = (el.services as ProfessionalServicesInterface[])
            .map((serviceProfessional: ProfessionalServicesInterface) => {
              return toPascalCase(
                services.find(
                  (service: INameValueId) =>
                    service._id === serviceProfessional.service,
                )?.name,
              )
            })
            .join(', ')
          const categoryName = (el.categories as Category[])
            .map((categoryProfessional: Category) => {
              return toPascalCase(
                categories.find(
                  (category: INameValueId) =>
                    category._id === categoryProfessional?.category,
                )?.name,
              )
            })
            .pop()
          const departmentName = () =>
            departments.find(
              (department: INameValueId) =>
                department._id === el.department?.department,
            )?.name

          return ({
            _id: el._id,
            key: `${el._id}_${el.firstSurname?.toLowerCase()}`,
            name: `${eachWord(el.firstSurname as string)} ${eachWord(
              el.secondSurname as string,
            )}${el.firstSurname && ', '}${eachWord(el.firstName as string)}`,
            rut: el.rut,
            code: el.code,
            categories: categoryName ?? 'Sin categoría',
            services: servicesNames ? servicesNames : 'Sin servicio',
            department: toPascalCase(departmentName()) ?? 'Sin departamento',
            status: `${homologueStatus(el.status as string)}`,
          } as unknown) as ProfessionalsData
        }),
      )
  }, [categories, departments, professionals, services])

  const startAccreditation = (record: AccreditedColumnsType) => ({
    onClick: () => history.push(`/profesional/${record._id}`),
  })

  return (
    <>
      <RowFile>
        <Col span={10}>
          <HeaderContainerFile>
            <h1>Profesionales Acreditados</h1>
          </HeaderContainerFile>
        </Col>
        <ColFile span={6}>
          <ButtonContainerFile>
            <ButtonHeaderBlueFile
              onClick={() => history.push('/ingreso-profesional')}>
              Crear Nuevo Profesional
            </ButtonHeaderBlueFile>
          </ButtonContainerFile>
        </ColFile>
      </RowFile>

      <Divider />
      {loading ? (
        <LoadingContent>
          <Spin size="large" />
        </LoadingContent>
      ) : (
        <Postulation>
          <Table
            onRow={record => startAccreditation(record)}
            columns={columns}
            dataSource={professionalData}
            rowKey="key"
            pagination={{ pageSize: 10 }}
          />
        </Postulation>
      )}
    </>
  )
}
