import { FC } from 'react'

import { HeaderPrivilegesServicesDetails } from './HeaderPrivilegesServicesDetails'
import { PrivilegesServicesDetailsContent } from './PrivilegesServicesDetailsContent'


export const ServicesPrivilegesDetails: FC = () => {
    return (
        <>
            <HeaderPrivilegesServicesDetails />
            <PrivilegesServicesDetailsContent
                title="Privilegios por Servicio"
                subtitle="Personal Médico Activo actualmente"
            />
        </>
    )
}
