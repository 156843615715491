import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'

import { StyledDividerReporting } from '../../../styles'

import {
  StyledHeaderCreateReportingContainer,
  StyledTitleHeaderCreateReporting,
} from './styles'

const HeaderCreateReporting: React.FC = () => {
  const history = useHistory()

  return (
    <>
      <StyledHeaderCreateReportingContainer>
        <StyledTitleHeaderCreateReporting>
          <ArrowLeftOutlined onClick={() => history.push('/reporteria')} />
          <h1 onClick={() => history.push('/reporteria')}>
            Volver a Dashboard
          </h1>
          <h4>Nuevo reporte personalizado</h4>
        </StyledTitleHeaderCreateReporting>
      </StyledHeaderCreateReportingContainer>
      <StyledDividerReporting />
    </>
  )
}

export default HeaderCreateReporting
