import { extname } from 'path'

import { useEffect, useMemo, useState } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Col, Row } from 'antd'
import { RcFile } from 'antd/es/upload/interface'
import { Mutations, Queries } from 'App/Graphql'
import Axios from 'axios'
import slugify from 'slugify'

import { IFileParam, UploadFile } from '../../../Types/UploadFileTypes'
import { LoadingContent as StyledSpinContainer, Progress as StyledProgress, Spin as StyledSpin, Upload } from '../styles'
import { DeleteOrMoveFileEnum, UploadComponentProps } from '../type'

import { authorizedBySave, handleChangeUpload, showConfirmModal } from './ConfirmModal'
import { SignatureUpload } from './SignatureUpload'


export const UploadComponent = ({
    listType,
    errorUploadsList,
    authorizedBy,
    authorizedById,
    dataUploadsList,
    refetchProfessional,
    loadingUploadsList,
    authorizedByName,
    professional,
    documentName,
    refetchUploadsList,
    disabled,
}: UploadComponentProps) => {
    const [fetchedFileUrl, setFetchedFileUrl] = useState<UploadFile[]>([])
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [kindOfAction, setKindOfAction] = useState('')
    const [fileToUpload, setFileToUpload] = useState<IFileParam>()
    const [uploadListDoc, setUploadListDoc] = useState({ key: '', size: 0 })
    const document = slugify(documentName, { lower: true })
    const [fetchFileUrl, { data: dataGetFetchFileUrl }] = useLazyQuery(
        Queries.GET_UPLOAD_FETCH_URL,
        {},
    )

    const [createFetchUrl] = useMutation(Mutations.CREATE_FETCH_URL)
    const [deleteOrMoveFile] = useMutation(Mutations.DELETE_OR_MOVE_FILE)
    const [getUrlForDelete] = useLazyQuery(Queries.DELETE_UPLOAD, {
        onCompleted: async () => {

            setUploadPercent(50)
            setFetchedFileUrl([])

            setTimeout(() => {
                setUploadPercent(0)
                refetchUploadsList()
            }, 2500)
            await deleteOrMoveFile({
                variables: {
                    key: uploadListDoc.key,
                    action: kindOfAction
                }
            })
        },
    })

    const [uploadUrl] = useLazyQuery(Queries.UPLOAD_URL, {
        onCompleted: async data => {
            const { uploadUrl } = data

            if (uploadUrl && fileToUpload) {
                const options = {
                    headers: {
                        'Content-Type': fileToUpload.file.type,
                    },
                    onUploadProgress(progressEvent: ProgressEvent) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        const roundedPercent = Math.round(percentCompleted / 10) * 10

                        setUploadPercent(percentCompleted)

                        if (roundedPercent === 100) setUploadPercent(roundedPercent)
                    },
                }
                const { uploadUrl } = data
                const response = await Axios.put(uploadUrl, fileToUpload.file, options)

                if (response.status === 200) {
                    authorizedByName && authorizedBySave({
                        authorizedBy,
                        authorizedById,
                        refetchProfessional,
                        authorizedByName,
                        professional
                    })
                    setFileToUpload(undefined)

                    if(uploadListDoc.key)
                    await deleteOrMoveFile({
                        variables: {
                            key: uploadListDoc.key,
                            action: kindOfAction
                        }
                    })
                    refetchUploadsList().then(() => setUploadPercent(0))
                }
            }
        },
    })

    useMemo(() => {
        if (fileToUpload) {
            const ext = extname(fileToUpload.file.name)

            const fileName = `${professional.code}-${document}${ext}`

            uploadUrl({
                variables: { code: professional.code, filename: fileName },
            })
        }
    }, [document, fileToUpload, professional, uploadUrl])

    useMemo(() => {
        if (dataUploadsList) {

            const arr = dataUploadsList.uploads.map(item => item)

            dataUploadsList.uploads.length > 0 && arr.reverse()

            const uploadListDoc = arr.find(item =>
                item.key.match(new RegExp(`${document}`)),
            )

            if (uploadListDoc) setUploadListDoc(uploadListDoc)
        }
    }, [dataUploadsList, document])

    useMemo(() => {
        if (uploadListDoc.key.length > 0 && listType === 'picture-card')
            fetchFileUrl({
                variables: { key: uploadListDoc.key },
            })
        else if (uploadListDoc.key.length > 0 && listType === 'text') {
            const filename = uploadListDoc.key.split('/')[3]
            const uid = Date.now()
            const ext = extname(filename).slice(1)

            setFetchedFileUrl([
                {
                    url: '#',
                    name: `${document}.${ext}`,
                    uid: `${uid}`,
                    size: uploadListDoc.size,
                    type: ext,
                    originFileObj: {
                        lastModifiedDate: new Date(),
                        name: `${document}.${ext}`,
                        size: uploadListDoc.size,
                        uid: `${uid}`,
                        lastModified: uploadListDoc.size,
                    } as RcFile,
                },
            ])
        }
    }, [document, fetchFileUrl, listType, uploadListDoc.key, uploadListDoc.size])

    useEffect(() => {
        if (dataGetFetchFileUrl && uploadListDoc.key.length > 0) {
            const filename = uploadListDoc.key.split('/')[2]
            const uid = Date.now()
            const ext = extname(filename).slice(1)

            setFetchedFileUrl([
                {
                    url: dataGetFetchFileUrl.uploadFetchUrl,
                    name: `${document}.${ext}`,
                    uid: `${uid}`,
                    size: uploadListDoc.size,
                    type: ext,
                    thumbUrl: dataGetFetchFileUrl.uploadFetchUrl,
                    originFileObj: {
                        lastModifiedDate: new Date(),
                        name: `${document}.${ext}`,
                        size: uploadListDoc.size,
                        uid: `${uid}`,
                        lastModified: uploadListDoc.size,
                    } as RcFile,
                },
            ])
        }
    }, [dataGetFetchFileUrl, uploadListDoc, document])

    const handlePreview = async () => {
        uploadListDoc.key.length > 0 &&
        createFetchUrl({
            variables: { key: uploadListDoc.key },
        }).then((data: { data: { createUploadUrl: string } } | any) => {
            const {
                data: { createFetchUrl: url },
            } = data

            window.open(url)
        })
    }

    return (
        <Row>
            <Col span={24}>
                <Row>
                    {listType === 'text' ? (
                        <Col span={24}>
                            {!errorUploadsList && !loadingUploadsList ? (
                                <Upload
                                    accept="application/pdf"
                                    beforeUpload={() => {
                                        return false
                                    }}
                                    name={document}
                                    listType={listType}
                                    onChange={file => {
                                        setKindOfAction(DeleteOrMoveFileEnum.MOVE)
                                        handleChangeUpload({
                                            file,
                                            professional,
                                            uploadListDoc,
                                            listType,
                                            setFileToUpload
                                        })
                                    }}
                                    fileList={fetchedFileUrl}
                                    onRemove={() => showConfirmModal({
                                            document, getUrlForDelete, uploadListDoc, setKindOfAction })
                                    }
                                    disabled={disabled}
                                    onPreview={handlePreview}
                                    scMargin
                                    scHidden={false}>
                                    {!loadingUploadsList && (
                                        <Button
                                            disabled={disabled}
                                            icon={<CloudUploadOutlined />}
                                        />
                                    )}
                                    {uploadPercent > 0 && (
                                        <StyledProgress
                                            percent={uploadPercent}
                                            strokeColor={{
                                                '0%': '#ABD838',
                                                '100%': '#ABDB38',
                                            }}
                                            size="small"
                                        />
                                    )}
                                </Upload>
                            ) : (
                                <StyledSpinContainer>
                                    <StyledSpin/>
                                </StyledSpinContainer>
                            )}
                        </Col>
                    ) : (
                        <SignatureUpload
                            errorUploadsList={errorUploadsList}
                            loadingUploadsList={loadingUploadsList}
                            document={document}
                            listType={listType}
                            professional={professional}
                            uploadListDoc={uploadListDoc}
                            setFileToUpload={setFileToUpload}
                            fetchedFileUrl={fetchedFileUrl}
                            getUrlForDelete={getUrlForDelete}
                            uploadPercent={uploadPercent}
                            setKindOfAction={setKindOfAction}
                        />
                    )}
                </Row>
            </Col>
        </Row>
    )
}
