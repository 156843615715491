import React, { createContext, useState } from 'react'

const authorizedRoles = ['ADMIN']

export const SessionContext = createContext({
  role: '',
  setRole: (value: string): void | string => {
    return value
  },
  isAuthorized: false,
  id: '',
  setId: (value: string): void | string => {
    return value
  },
})

export const SessionProvider: React.FC = ({ children }) => {
  const [role, setRole] = useState('')
  const isAuthorized =
    role !== undefined && role !== '' && authorizedRoles.includes(role)
  const [id, setId] = useState('')

  return (
    <SessionContext.Provider value={{ role, setRole, isAuthorized, id, setId }}>
      {children}
    </SessionContext.Provider>
  )
}
