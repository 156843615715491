import { CheckBoxGeneralInfoType } from './types'

export const initialGeneralInfoCheckboxes: CheckBoxGeneralInfoType[] = [
    {
        title: 'Nombres',
        name: 'name',
    },
    {
        title: 'RUT',
        name: 'rut',
    },
    {
        title: 'Dirección',
        name: 'direction',
    },
    {
        title: 'Estado Civil',
        name: 'maritalStatus',
    },
]
