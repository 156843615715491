import {
  CompletedStatus,
  ProfessionalStatus,
  TypeSelect,
  VaccineType,
  ValidationStatus,
} from './types'

export const initialValues = {
  categories: [] as string[],
  departments: [] as string[],
  inductionDepartment: ([] as unknown) as CompletedStatus[],
  inductionServices: ([] as unknown) as CompletedStatus[],
  infectionCourses: ([] as unknown) as ValidationStatus[],
  institutionalOrientation: ([] as unknown) as CompletedStatus[],
  liabilityInsurance: ([] as unknown) as ValidationStatus[],
  privilegeDepartment: ([] as unknown) as ValidationStatus[],
  privilegeServices: ([] as unknown) as ValidationStatus[],
  professionalAccreditation: ([] as unknown) as ProfessionalStatus[],
  resuscitationCourses: ([] as unknown) as ValidationStatus[],
  sedationCourses: ([] as unknown) as ValidationStatus[],
  services: [] as string[],
  specialties: [] as string[],
  vaccine: [] as unknown as VaccineType[],
}

export const PROFESSIONAL_STATUSES: TypeSelect[] = [
  { text: 'Acreditado No Activo', value: 'NON_ACTIVE_ACCREDITED' },
  { text: 'Acreditado', value: 'ACTIVE_ACCREDITED' },
  // { text: 'En Aplicacion', value: 'IN_APPLICATION' },
  { text: 'Egresado', value: 'DISCHARGED' },
  { text: 'Suspendido', value: 'SUSPENDED' },
]

export const PRIVILEGES_STATUSES: TypeSelect[] = [
  { text: 'Vigente', value: 'VALID' },
  { text: 'No Asignado', value: 'NOT_ASSIGNED' },
  { text: 'No Vigente', value: 'NOT_VALID' },
]

export const ORIENTATION_STATUSES: TypeSelect[] = [
  { value: 'FULFILLED', text: 'Realizada' },
  { value: 'PENDING', text: 'Pendiente' },
]

export const VACCINE_STATUSES: TypeSelect[] = [
  { value: 'COMPLETED', text: 'Completo' },
  { value: 'INCOMPLETE', text: 'Incompleto' },
]
