import { gql } from '@apollo/client'

export const GET_ADMINS_QUERY = gql`
  query allAdmins {
    allAdmins {
      _id
      firstName
      lastName
    }
  }
`

export const GET_USER_SESSION = gql`
  query session {
    session {
      _id
      firstName
      lastName
      avatar
      role
    }
  }
`

export const GET_ALL_PROFESSIONALS = gql`
  query allProfessionals {
    allProfessionals {
      _id
      rut
      firstName
      code
      firstSurname
      secondSurname
      status
      specialty {
        specialty {
          _id
          name
        }
      }
      services {
        service
      }
      department {
        department
      }
      categories {
        category
      }
    }
  }
`

export const GET_COMMUNES = gql`
  query allCommunes {
    allCommunes {
      _id
      name
    }
  }
`

export const GET_ALL_COUNTRIES = gql`
  query allCountries {
    allCountries {
      _id
      name
      code
      nationality
      acronym
    }
  }
`

export const GET_ALL_LANGUAGES = gql`
  query allLanguages {
    allLanguages {
      id
      name
      value
    }
  }
`

export const GET_MARITAL_STATUS = gql`
  query allMaritalStatus {
    allMaritalStatus {
      id
      name
      value
    }
  }
`

export const GET_TYPE_BILLING = gql`
  query allTypeBilling {
    allTypeBilling {
      id
      name
      value
    }
  }
`

export const GET_ALL_REGIONS = gql`
  query allRegions {  
    allRegions {
      _id
      name
      clcId
      number
      acronym
      active
    }
  }
`

export const GET_ALL_NATIONALITIES = gql`
  query allNationalities {
    allNationalities {
      _id
      name
      clcId
    }
  }
`

export const GET_MANY_COMMUNES = gql`
  query manyCommunes($input: SearchCommunesInput!) {
    manyCommunes(input: $input) {
      _id
      name
    }
  }
`

export const GET_ALL_CITIES = gql`
  query allCitiesByRegion($_id: ID!) {
    allCitiesByRegion (_id: $_id) {
      _id
      name
      clcId
    }
  }
`

export const GET_ALL_SELECTS = gql`
  {
      allSelects {
        languages {
           name
          value
        }
        countries {
          _id
          name
          code
          nationality
          acronym
        }
        maritalStatus {
          name
          value
        }
        typeBilling {
          name
          value
    }
  }
}

`
