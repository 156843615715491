import { ApolloQueryResult, MutationFunctionOptions } from '@apollo/client'
import { FetchResult } from 'apollo-boost'
import { IProfessional } from 'App/Types/GlobalTypes'

import { IFileParam, IUploadList } from '../../Types/UploadFileTypes'


export type UploadInputProps = {
    professional: IProfessional
    documentName: string
    dataUploadsList: IUploadList
    loadingUploadsList: boolean
    errorUploadsList: any
    refetchUploadsList: (
        variables?: Partial<any> | undefined,
    ) => Promise<ApolloQueryResult<any>>
    listType: string | any
    disabled?: boolean
    authorizedBy?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
    ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
    authorizedById?: string
    authorizedByName?: string
    refetchProfessional?: (
    variables?: Partial<any> | undefined,
) => Promise<ApolloQueryResult<any>>
}

export type UploadComponentProps = {
    listType?: string | any
    errorUploadsList: any
    authorizedById?: string
    loadingUploadsList: any
    professional: IProfessional
    authorizedBy?: any
    documentName: string
    refetchUploadsList: (
        variables?: Partial<any> | undefined,
    ) => Promise<ApolloQueryResult<any>>
    refetchProfessional: any
    authorizedByName?: string
    disabled: boolean
    dataUploadsList: IUploadList
}

export enum DeleteOrMoveFileEnum {
    DELETE = 'DELETE',
    MOVE = 'MOVE'
}

export type ShowConfirmModalProps = {
    document: string
    getUrlForDelete: any
    uploadListDoc: any
    setKindOfAction: any
}

export type HandleChangeUploadProps = {
    file: IFileParam
    professional: IProfessional
    uploadListDoc: any
    listType?: string
    setFileToUpload: any
}

export type AuthorizedBySaveProps = {
    authorizedByName?: string
    authorizedBy?: string | any
    professional: IProfessional
    refetchProfessional: any
    authorizedById?: string
}

export type SignatureUploadProps = {
    errorUploadsList: any
    loadingUploadsList: any
    document: any
    listType: any
    professional: any
    uploadListDoc: any
    setFileToUpload: any
    fetchedFileUrl: any
    getUrlForDelete: any
    uploadPercent: any
    setKindOfAction: any
}
