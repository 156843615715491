import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { TooltipProps } from 'recharts'

import {
    StyledDashboardContainerFather,
    StyledHeaderDetails,
    StyledHeading4,
    StyledTitleHeaderDetails
} from '../styles'


const StylesTooltip = {
    border: '1px solid',
    background: 'white',
    padding: '5px',
    borderRadius: '5px'
}

export const colors: string[] = [
    '#495FA4',
    '#87C54A',
    '#6073C1',
    '#50968B',
    '#4FCAB8',
    '#BAFF76',
    '#ffcb91',
    '#94ebcd',
    '#ffefa1',
]

export const CustomTooltip = ({ payload, active, label }: TooltipProps<string, number>) => {

    const departmentName = () => {
        if (active && payload && payload.length)
            return (
                <div>
                    <p className="label">{`${label} : (${[...payload]?.shift()?.payload.total})`}</p>
                </div>
            )
    }

    return (
        <div style={StylesTooltip}>
            {departmentName()}
            {payload?.map((payload): JSX.Element => {
                return (
                    <StyledHeading4
                        $headingColor={payload.color as string}
                        key={payload.name}
                    >
                        {`${payload.name} : ${payload.value}`}
                    </StyledHeading4>
                )
            })}
        </div>
    )
}

export const HeaderDetails: FC = () => {
    const history = useHistory()

    return (
        <StyledDashboardContainerFather>
            <StyledHeaderDetails>
                <StyledTitleHeaderDetails>
                    <ArrowLeftOutlined onClick={() => history.push('/reporteria')} />
                    <h1 onClick={() => history.push('/reporteria')}>
                        Volver a Dashboard
                    </h1>
                </StyledTitleHeaderDetails>
            </StyledHeaderDetails>
        </StyledDashboardContainerFather>
    )
}
