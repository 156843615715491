/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { slug } from 'App/Helpers/Slugifier'
import { Options } from 'App/Screens/MedicalRecord/styles'
import {
  IErrorMessage,
  INameValueId,
  OptionListItem,
} from 'App/Types/GlobalTypes'
import { ITarget } from 'App/Types/HooksTypes'
import * as _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { OptionData, OptionGroupData } from 'rc-select/lib/interface'

import * as fields from '../Constants'

/**
 * check if an object is null or not
 *
 * @param {*} obj
 * @return {*}  {boolean}
 *
 */
export const checkIfNull = (obj: any): boolean => {
  for (const key in obj) if (obj[key]) return false

  return true
}

/**
 * returns an string with 'str...' format if the number is minor than str length
 *
 * @param {string} str
 * @param {number} num
 * @return {*}  {string}
 *
 */
export const truncateString = (str: string, num: number): string => {
  // Clear out that junk in your trunk
  if (str.length > num) return `${str.slice(0, num)}...`
  else return str
}

/**
 * uppercase first letter in a word
 *
 * @param {string} word
 * @return {*}  {string}
 *
 */
export const ucWords = (word: string): string => {
  if (!word) return word

  return `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`
}

/**
 * Uppercase each word of a text
 *
 * @param {string} text
 * @return {*}  {string}
 *
 */
export const eachWord = (text: string): string => {
  if (!text) return ''

  // Removes the leading and trailing white space and line terminator characters from a string
  let propText = text.trim()

  // if (propText.includes('-')) propText = propText.split('-').join(' ');
  if (propText.includes('_')) propText = propText.split('_').join(' ')

  // If has not second name return text
  if (!propText.includes(' ')) return ucWords(text)

  // Make an array with all words
  const words = propText.split(' ')

  // Return each word in required format
  return words
    .filter((word: string) => word.length > 0)
    .map(
      (word: string) =>
        word[0] && word[0].toUpperCase() + word.substring(1).toLowerCase(),
    )
    .join(' ')
}

export const filterOptionsFunction = (
  input: string,
  option: OptionData | OptionGroupData | undefined,
) =>
  slug(option?.children.toLowerCase()).indexOf(slug(input.toLowerCase())) >= 0

export const renderOptionsList = (optionsList: OptionListItem[]) =>
  !isEmpty(optionsList) &&
  optionsList.map((option: OptionListItem) => (
    <Options key={option._id} value={option.value}>
      {eachWord(option.label)}
    </Options>
  ))

export const getFriendlyErrors = (err: IErrorMessage) => {
  const errMessage = err?.message?.includes(',')
    ? err?.message?.split(',')
    : err?.message?.split(':') // .slice(1, undefined);

  const errorsArray =
    Array.isArray(errMessage) &&
    errMessage
      .map((element: any) =>
        element?.includes('rut must not be empty')
          ? 'Rut esta vacio'
          : element?.includes('Professional name is required')
          ? 'El Nombre esta vacio'
          : element?.includes('email') && element?.includes('required')
          ? 'El email esta vacio'
          : element?.includes('phone') && element?.includes('required')
          ? 'El teléfono esta vacio'
          : element?.includes('commune')
          ? 'Error en la Comuna'
          : element?.includes('typeBilling')
          ? 'El tipo de facturacion no fue seleccionado'
          : element?.includes('rut') && element?.includes('Validator')
          ? 'Rut con formato incorrecto o ya existe'
          : element?.includes('Response not successful')
          ? 'Sin respuesta del servidor'
          : element?.includes('email_-1')
          ? 'El email está registrado para otro profesional'
          : element?.includes('phone_1 dup key')
          ? 'El Teléfono ingresado ya se encuentra registrado o no ha sido ingresado'
          : element?.includes('cellPhone_1 dup key')
          ? 'El Teléfono (opcional) ingresado ya se encuentra registrado o no ha sido ingresado'
          : element?.includes('rut_-1 dup key')
          ? 'El Rut esta registrado para otro profesional'
          : element?.includes('Failed to fetch')
          ? 'No se pudo realizar la solicitud'
          : undefined,
      )
      .filter(e => e)

  return errorsArray && errorsArray.length > 0 ? errorsArray : []
}

/**
 * Returns an array with the following keys: id, name, value.
 *
 * @param {INameValueId} marital
 * @return {*}  {(INameValueId[] | any[])}
 *
 */
export const homologueMarital = (
  marital: INameValueId,
): INameValueId[] | any[] => {
  if (!Array.isArray(marital)) return []

  const maritalArray = marital
    .map(({ name, value, id }) =>
      name === 'Single'
        ? { name: 'Soltero/a', value, id }
        : name === 'Married'
        ? { name: 'Casado/a', value, id }
        : name === 'Widower'
        ? { name: 'Viudo/a', value, id }
        : name === 'Divorced'
        ? { name: 'Divorciado/a', value, id }
        : name === 'Separated'
        ? { name: 'Separado/a', value, id }
        : name === 'Engaged'
        ? { name: 'Comprometido/a', value, id }
        : name === 'Cohabiting'
        ? { name: 'Conviviente', value, id }
        : name === 'NotSpecified'
        ? { name: 'Prefiero no especificar', value, id }
        : undefined,
    )
    .filter(el => el)
    .sort((a: any, b: any) => a.name.localeCompare(b.name))

  return maritalArray && maritalArray.length > 0 ? maritalArray : []
}

export const homologueNationality = (nationality: any[]): any[] => {
  if (!Array.isArray(nationality)) return []

  return nationality
    .map(({ name, value, _id, clcId }) =>
      name === 'Chilean'
        ? { name: 'Chileno/a', value, _id, clcId }
        : name === 'Argentine'
        ? { name: 'Argentino/a', value, _id, clcId }
        : name === 'Britons'
        ? { name: 'Británico/a', value, _id, clcId }
        : name === 'Venezuelan'
        ? { name: 'Venezolano/a', value, _id, clcId }
        : name === 'Nipponese'
        ? { name: 'Japonés/a', value, _id, clcId }
        : undefined,
    )
    .filter(el => el)
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
}

export const homologueStatus = (status: string): string => {
  if (typeof status !== 'string') return 'Estado no definido'

  let professionalStatus = ''

  switch (true) {
    case status === fields.STATUS_ACTIVE_ACCREDITED:
      professionalStatus = 'Acreditado'
      break
    case status === fields.STATUS_NON_ACTIVE_ACCREDITED:
      professionalStatus = 'Acreditado No Activo'
      break
    case status === fields.STATUS_DISCHARGED:
      professionalStatus = 'Egresado'
      break
    case status === fields.STATUS_IN_APPLICATION:
      professionalStatus = 'En Postulación'
      break
    case status === fields.STATUS_SUSPENDED:
      professionalStatus = 'Suspendido'
      break
    default:
      professionalStatus = 'Estado no definido'
      break
  }

  return professionalStatus
}

export const homologueLanguages = (languages: any[]): any[] => {
  if (!Array.isArray(languages)) return []

  return languages
    .map(({ name, value }) =>
      name === 'English'
        ? { name: 'Inglés', value }
        : name === 'Spanish'
        ? { name: 'Español', value }
        : name === 'German'
        ? { name: 'Alemán', value }
        : name === 'French'
        ? { name: 'Frances', value }
        : name === 'Italian'
        ? { name: 'Italiano', value }
        : name === 'Portuguese'
        ? { name: 'Portugués', value }
        : name === 'Japanese'
        ? { name: 'Japonés', value }
        : undefined,
    )
    .filter(el => el)
    .sort((a: any, b: any) => a.name.localeCompare(b.name))
}

/**
 * Returns a language from English to Spanish
 *
 * @param {string} language in english {string}
 * @return {string} language in spanish {string}
 *
 */
export const homologueSingleLanguage = (language: string): string => {
  if (typeof language !== 'string') return 'Language not defined'

  let professionalLanguage = ''

  switch (language.toLowerCase()) {
    case 'english':
      professionalLanguage = 'Inglés'
      break
    case 'spanish':
      professionalLanguage = 'Español'
      break
    case 'german':
      professionalLanguage = 'Alemán'
      break
    case 'french':
      professionalLanguage = 'Frances'
      break
    case 'italian':
      professionalLanguage = 'Italiano'
      break
    case 'portuguese':
      professionalLanguage = 'Portugués'
      break
    case 'japanese':
      professionalLanguage = 'Japonés'
      break
    default:
      professionalLanguage = 'Lenguaje no definido'
      break
  }

  return professionalLanguage
}

export const homologueService = (services: string): string => {
  if (typeof services !== 'string') return 'Service not defined'

  let professionalService = ''

  switch (services.toLowerCase()) {
    case 'First Service':
      professionalService = 'Primer Servicio'
      break
    case 'Second Service':
      professionalService = 'Segundo Servicio'
      break
    default:
      professionalService = 'Service not defined'
  }

  return professionalService
}

// export const translateProfessionalStatus = (
//   status: string,
// ): { status: string; color: string } => {
//   return status === fields.ACCREDITED_ACTIVE
//     ? { status: 'Acreditado Activo', color: '#ABD838' }
//     : status === fields.ACCREDITED_INACTIVE
//     ? { status: 'Acreditado No Activo', color: '#D8C638' }
//     : status === fields.NONACCREDITED_ACTIVE
//     ? { status: 'No Acreditado Activo', color: '#ABD838' }
//     : status === fields.NONACCREDITED_INACTIVE
//     ? { status: 'No Acreditado Inactivo', color: '#D8C638' }
//     : status === fields.RETIRED
//     ? { status: 'Egresado', color: '#F43C78' }
//     : status === fields.SUSPENDED
//     ? { status: 'Suspendido', color: '#D8C638' }
//     : { status: '', color: '' }
// }

export const translateProfessionalStatus = (
  status: string,
): { status: string; color: string } => {
  return status === fields.STATUS_ACTIVE_ACCREDITED
    ? { status: 'Acreditado', color: '#ABD838' }
    : status === fields.STATUS_IN_APPLICATION
    ? { status: 'En Postulación', color: '#D8C638' }
    : status === fields.STATUS_NON_ACTIVE_ACCREDITED
    ? { status: 'Acreditado No Activo', color: '#D8C638' }
    : status === fields.STATUS_SUSPENDED
    ? { status: 'Suspendido', color: '#D8C638' }
    : status === fields.STATUS_DISCHARGED
    ? { status: 'Egresado', color: '#F43C78' }
    : { status: '', color: '' }
}

export const mutationFormat = (formValues: any) => {
  return {
    education: {
      applymentFile: formValues.applymentFile,
      resume: formValues.resume,
      sisDocument: formValues.sisDocument,
    },
    phone: formValues.phone,
    phoneCode: formValues.phoneCode,
    cellPhone: formValues.cellPhone,
    cellPhoneCode: formValues.cellPhoneCode,
    address: {
      region:
        (formValues.regionAddressTemp &&
          _.isString(formValues.regionAddressTemp) &&
          formValues.regionAddressTemp.toLowerCase() === 'metropolitana') ||
        !formValues.regionAddressTemp
          ? null
          : formValues.regionAddressTemp,
      city: formValues.cityAddressTemp ? formValues.cityAddressTemp : null,
      commune: formValues.communeAddressTemp
        ? formValues.communeAddressTemp
        : null,
      detail: formValues.detailAddressTemp,
    },
    corporativeEmail: formValues.corporativeEmail,
    email: formValues.email,
    firstName: formValues.firstName,
    firstSurname: formValues.firstSurname,
    gender: !formValues.gender ? 'OTHER' : formValues.gender,
    languages: formValues.languages,
    maritalStatus: !formValues.maritalStatus
      ? 'NOT_SPECIFIED'
      : formValues.maritalStatus,
    nationality: !formValues.nationality ? undefined : formValues.nationality,
    rut: formValues.rut,
    secondSurname: formValues.secondSurname,
    birthday: formValues.birthday,
  }
}

export const fiendlyName = (el: string) => {
  if (typeof el !== 'string')

  return el === 'firstName'
    ? 'Nombre'
    : el === 'firstSurname'
    ? 'Apellido Paterno'
    : el === 'secondSurname'
    ? 'Apellido Materno'
    : el === 'maritalStatus'
    ? 'Estado civil'
    : el === 'nationality'
    ? 'Nacionalidad'
    : el === 'rut'
    ? 'Rut'
    : el === 'commune'
    ? 'Comuna'
    : el === 'address'
    ? 'Direccion'
    : el === 'phone'
    ? 'Telefono'
    : el === 'cellPhone'
    ? 'Celular'
    : el === 'email'
    ? 'Email personal'
    : el === 'corporativeEmail'
    ? 'Email corporativo'
    : el === 'gender'
    ? 'Sexo'
    : el === 'birthday'
    ? 'Fecha de Nacimiento'
    : el === 'applymentFile'
    ? 'Postulacion'
    : el === 'resume'
    ? 'CV'
    : el === 'sisDocument'
    ? 'Registro de Prestaciones'
    : el === 'typeBilling'
    ? 'Tipo de Facturacion'
    : el === 'billingRutTemp'
    ? 'Rut de Sociedad'
    : el === 'billingAddressTemp'
    ? 'Domicilio Tributario'
    : el === 'billingNameTemp'
    ? 'Razon social'
    : el === 'bank'
    ? 'Banco'
    : el === 'accountNumber'
    ? 'Nmro. Cuenta'
    : ''
}

export const parseRut = (rut: string): string => {
  const upper = rut.toUpperCase()
  const splitted = upper.split('')
  const filtered = splitted.filter(char => /[0-9]|K/.test(char))

  if (filtered.length > 1) {
    const numbers = filtered
      .slice(0, filtered.length - 1)
      .filter(char => /[0-9]/.test(char))
      .slice(0, 8)
    const array = Array(Math.floor((numbers.length - 1) / 3))
      .fill(0)
      .map((_, index) => ++index)

    for (const x of array) {
      numbers.reverse()
      numbers.splice(3 * x + x - 1, 0, '.')
      numbers.reverse()
    }

    const verificator = filtered.slice(filtered.length - 1, filtered.length)

    return `${numbers.join('')}-${verificator.join('')}`
  }

  return filtered.join('')
}

/**
 * return sentence in pascal case
 *
 * @param {string} sentence
 * @return {*}  {string}
 *
 */
export const toPascalCase = (sentence: string): string => {
  if (!sentence) return sentence

  return sentence
    .split(' ')
    .filter(word => word.length > 0)
    .map(word => `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join(' ')
}

export const onModifiedRut = (
  rut: string,
  handler: ({ target }: { target: ITarget }) => void,
) => {
  if (rut) {
    const formattedRut = parseRut(rut)

    handler({
      target: {
        name: 'rut',
        value: formattedRut,
      },
    })

    return formattedRut
  }
}

/**
 * return sorted array
 * @param array desired array to sort
 * @param property by which property
 * @param type type of variable, default is number
 * @param direction 1 = asc, -1 = desc, default is 1
 */
export const sortArrayByProperty = (
  array: Record<string, string | number | Date>[],
  property: string,
  type = 'number',
  direction = 1,
) => {
  if (type === 'date')
    return array.sort(
      (a, b) =>
        (new Date(a[property]).getTime() - new Date(b[property]).getTime()) *
        direction,
    )

  if (type === 'string')
    return array.sort((a, b) => {
      const valA = a[property],
        valB = b[property]

      if (typeof valA === 'string' && typeof valB === 'string')
        return valA.localeCompare(valB) * direction

      return 0
    })

  return array.sort((a, b) => {
    const valA = a[property],
      valB = b[property]

    if (typeof valA === 'number' && typeof valB === 'number')
      (valA - valB) * direction

    return 0
  })
}
