import { gql } from '@apollo/client'

export const PRIVILEGES_DEPARTMENTS_DASHBOARD = gql`
  query privilegesDepartmentsDashboard($date: Date, $departments: [String]) {
    privilegesDepartmentsDashboard(date: $date, departments: $departments) {
      totalData
      percentage
      status
      quantity
    }
  }
`

export const PRIVILEGES_SERVICES_DASHBOARD = gql`
  query privilegesServicesDashboard($date: Date, $services: [String]) {
    privilegesServicesDashboard(date: $date, services: $services) {
      totalData
      percentage
      status
      quantity
    }
  }
`

export const CATEGORIES_DASHBOARD = gql`
  query categoriesDashboard {
    categoriesDashboard {
      totalData
      categories {
        category
        quantity
        categoryName
      }
    }
  }
`

export const INSTITUTIONAL_DASHBOARD = gql`
  query institutionalOrientationInductionDashboard {
    institutionalOrientationDashboard {
      totalData
      quantity
      status
      percentage
    }
  }
`

export const DEPARTMENT_DASHBOARD = gql`
  query departmentInductionDashboard {
    departmentInductionDashboard {
      totalData
      quantity
      status
      percentage
    }
  }
`

export const SERVICES_DASHBOARD = gql`
  query servicesInductionDashboard {
    servicesInductionDashboard {
      totalData
      quantity
      status
      percentage
    }
  }
`

export const MEDICAL_CENSUS_SERVICES_DASHBOARD = gql`
  query medicalCensusServicesDashboard {
    medicalCensusServicesDashboard {
      uniquecategories
      uniqueservices
      totalData
    }
  }
`

export const MEDICAL_CENSUS_DEPARTMENTS_DASHBOARD = gql`
  query medicalCensusDepartmentsDashboard {
    medicalCensusDepartmentsDashboard {
      uniquecategories
      uniquedepartments
      totalData
    }
  }
`

export const INCOMING_DEPARTMENTS_DASHBOARD = gql`
  query incomingDepartmentsDashboard($startDate: Date, $endDate: Date) {
    incomingDepartmentsDashboard(startDate: $startDate, endDate: $endDate) {
      totalData
      uniquecategories
      uniquedepartments
    }
  }
`

export const OUTGOING_DEPARTMENTS_DASHBOARD = gql`
  query outgoingDepartmentsDashboard($startDate: Date, $endDate: Date) {
    outgoingDepartmentsDashboard(startDate: $startDate, endDate: $endDate) {
      totalData
      uniquecategories
      uniquedepartments
    }
  }
`

export const INCOMING_SERVICES_DASHBOARD = gql`
  query incomingServicesDashboard($startDate: Date, $endDate: Date) {
    incomingServicesDashboard(startDate: $startDate, endDate: $endDate) {
      totalData
      uniquecategories
      uniqueservices
    }
  }
`

export const OUTGOING_SERVICES_DASHBOARD = gql`
  query outgoingServicesDashboard($startDate: Date, $endDate: Date) {
    outgoingServicesDashboard(startDate: $startDate, endDate: $endDate) {
      totalData
      uniquecategories
      uniqueservices
    }
  }
`

export const ALL_PROFESSIONALS_FILTERED = gql`
  query allProfessionalsFiltered($input: AllProfessionalsFilteredInput) {
    allProfessionalsFiltered(input: $input)
  }
`

export const INDUCTION_DEPARTMENTS_DETAILS_DASHBOARD = gql`
  query departmentInductionDetailsDashboard {
    medicalCensusDepartmentsDetailsDashboard {
      totalData
      listOfKeys
      counters {
        name
        count
      }
      elements {
        name
        total
        elements {
          name
          count
          total
        }
      }
    }
  }
`

export const INSTITUTIONAL_ORIENTATION_DETAILS_DASHBOARD = gql`
  query institutionalOrientationDetailsDashboard {
    institutionalOrientationDetailsDashboard {
      totalData
      listOfKeys
      counters {
        name
        count
      }
      elements {
        name
        total
        elements {
          name
          count
          total
        }
      }
    }
  }
`

export const PRIVILEGES_SERVICES_DETAILS_DASHBOARD = gql`
  query privilegesServicesDetailsDashboard {
    privilegesServicesDetailsDashboard {
      totalData
      listOfKeys
      counters{
        name
        count
      }
      elements{
        name
        total
        elements{
          name
          count
          total
        }
      }
    }
  }
`

export const PRIVILEGES_DEPARTMENT_DETAILS_DASHBOARD = gql`
    query privilegesDepartmentDetailsDashboard {
        privilegesDepartmentsDetailsDashboard {
            totalData
            listOfKeys
            counters{
                name
                count
            }
            elements{
                name
                total
                elements{
                    name
                    count
                    total
                }
            }
        }
    }
`

export const INDUCTION_SERVICES_DETAILS_DASHBOARD = gql`
  query serviceInductionDetailsDashboard {
    medicalCensusServicesDetailsDashboard {
      totalData
      listOfKeys
      counters {
        name
        count
      }
      elements {
        name
        total
        elements {
          name
          count
          total
        }
      }
    }
  }
`

