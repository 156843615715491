import { ColumnsType } from 'antd/lib/table'
import { eachWord, toPascalCase } from 'App/Helpers/utils'

import { getTextByStatus } from '../../../MedicalCensusReporting/details/departments/MedicalCensusDepartmentsDetailsContent'
import {
    CustomColumnsType,
    DataElementsDetailsDashboard,
    DataElementsForDetailsDashboard,
    DetailsColumnsType,
    DetailsDashboardDocument,
    Statuses
} from '../../../MedicalCensusReporting/details/departments/types'



export const getFormattedDetailsData: any = (data: DetailsDashboardDocument) =>
    data.elements
        .map((element: DataElementsForDetailsDashboard) => {
            const { name, total } = element
            let itemObj: any = {
                key: name,
                department: toPascalCase(name),
            }

            data.listOfKeys.forEach((cat: string) => {
                itemObj = {
                    ...itemObj,
                    [eachWord(cat)]: 0,
                }
            })

            element.elements.forEach(
                ({ name, count }: DataElementsDetailsDashboard) => {
                    itemObj = {
                        ...itemObj,
                        total,
                        [eachWord(name)]: count,
                    }
                },
            )

            return { name: eachWord(name), ...itemObj, total }
        })
        .sort((a, b) => b.total - a.total * 1)

export const columnsTable = (
    data: DetailsDashboardDocument,
): ColumnsType<DetailsColumnsType> => {
    const itemArr = [
        {
            title: 'Servicios',
            dataIndex: 'department',
            width: 200,
        },
    ]

    data.listOfKeys.forEach((name: string) => {
        const title = eachWord(getTextByStatus(name as Statuses))
        const newItem = {
            title,
            dataIndex: eachWord(name),
            width: 100,
        }

        itemArr.push(newItem as CustomColumnsType)
    })

    itemArr.push({
        title: 'Total',
        dataIndex: 'total',
        width: 80,
    })

    return itemArr
}
