import React from 'react'
import { useHistory } from 'react-router-dom'

import { toPascalCase } from '../../../Helpers/utils'
import { SearchBar } from '../styles'

const SearchComponent: React.FC = () => {
  const history = useHistory()

  const onSearch = (value: string) => {
    if(value !== '')
    history.push(`/busqueda?q=${value}`)
  }

  return (
    <SearchBar
      size="large"
      onSearch={value => onSearch(toPascalCase(value))}
      placeholder="Búsqueda general del SAM"
    />
  )
}

export default SearchComponent
