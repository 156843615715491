/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react'
import { Col, message } from 'antd'
import { Auth } from 'aws-amplify'

import pkg from '../../../../package.json'
import SvgComponent from '../../../App/assets/SVG/LogoClc'
import {
  ColForm2,
  HeaderLogin,
  LoginCol,
  LoginRow,
  Span,
  SvgVersionRow,
  VersionCol,
} from '../LoginScreen/styles'

import * as fields from './../../Helpers/Constants'
import { LoginUser, NewPassword, RestorePassword } from './components'

const LoginScreen = ({ history, path }: { history: any; path: any }) => {
  const [userName, setUserName] = useState('')
  const [secretPassword, setSecretPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  // eslint-disable-next-line
  const [, setConfirmNewPassword] = useState('')
  const [completePasswordForm, setCompletePasswordForm] = useState(false)
  const [activeResetPassword, setActiveResetPassword] = useState(false)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    Auth.currentSession()
      .then(() => history.push(path))
      .catch((err: any) => {
        console.log(err)
      })
    // eslint-disable-next-line
  }, [history])

  const onFinish = (values: any) => {
    const { username, password } = values

    password && setSecretPassword(password)
    setErrorMessage('')
    setLoading(true)
    Auth.signIn(username, password || secretPassword)
      .then(user => {
        if (
          user.challengeName === fields.NEW_PASSWORD_REQUIRED &&
          !completePasswordForm
        ) {
          setLoading(false)
          setCompletePasswordForm(true)
        } else if (
          user.challengeName === fields.NEW_PASSWORD_REQUIRED &&
          completePasswordForm
        )
          Auth.completeNewPassword(user, newPassword, { name: username })
            .then(() => {
              history.push(path)
            })
            .catch(() => {
              message.info(
                'Hubo un error, inténtelo de nuevo. Recuerda que las contraseñas son mínimo 10 caracteres',
              )
              setLoading(false)
            })
        else if (
          user.challengeName === fields.NEW_PASSWORD_REQUIRED &&
          activeResetPassword
        ) {
          console.log('Reset ?')
          Auth.forgotPassword(user)
            .then(data => {
              alert('wena')
              console.log(data)
            })
            .catch(err => {
              alert('eppa')
              console.log(err)
            })
        } else history.push(path)
      })
      .catch(err => {
        setErrorMessage(err.message)
        setLoading(false)
      })
  }

  const onFinishFailed = () => {
    message.info('Hubo un error, Inténtelo de nuevo.')
  }

  return (
    <LoginRow justify="center">
      <LoginCol span={8}>
        <SvgVersionRow>
          <Col>
            <SvgComponent />
          </Col>
          <VersionCol>
            <Span>{`Versión ${pkg.version}`}</Span>
          </VersionCol>
        </SvgVersionRow>
        <HeaderLogin>
          <h2>Te damos la bienvenida al Nuevo SAM</h2>
          <h3>Sistema de Acreditación Médica</h3>
        </HeaderLogin>
        {!completePasswordForm ? (
          <>
            {!activeResetPassword ? (
              <LoginUser
                activeResetPassword={activeResetPassword}
                loading={loading}
                errorMessage={errorMessage}
                setUserName={setUserName}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                setActiveResetPassword={setActiveResetPassword}
              />
            ) : (
              <RestorePassword
                loading={loading}
                setActiveResetPassword={setActiveResetPassword}
                userName={userName}
                setUserName={setUserName}
                setLoading={setLoading}
              />
            )}
          </>
        ) : (
          <NewPassword
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            userName={userName}
            loading={loading}
            setConfirmNewPassword={setConfirmNewPassword}
            setUserName={setUserName}
            setNewPassword={setNewPassword}
          />
        )}
      </LoginCol>
      <ColForm2 span={8}></ColForm2>
    </LoginRow>
  )
}

export default LoginScreen
