import React, { Dispatch, SetStateAction } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { IFileParam, IUploadFile, UploadFile } from 'App/Types/UploadFileTypes'
import slugify from 'slugify'

import { Upload } from '../UploadInput/styles'

import * as notification from './../../Helpers/Notification'

const UploadInputOnSave = ({
  documentName,
  files,
  setFiles,
}: {
  documentName: string
  files: IUploadFile[]
  setFiles: Dispatch<SetStateAction<IUploadFile[]>>
}): JSX.Element => {
  const [fileList, setFileList] = React.useState<UploadFile[]>([])
  const allowedTypes = ['application/pdf']

  const document = slugify(documentName, { lower: true })
  const maximumSize = 5 * 1024 * 1024 // 5 MB

  const handleChangeUpload = (file: IFileParam) => {
    if (!allowedTypes.includes(file.file.type)) {
      notification.openWarningNotification('¡Advertencia!')(
        'Tipo de archivo no permitido',
      )

      return false
    } else if (file.file.size > maximumSize) {
      const maximumSizeReadable = maximumSize / (1024 * 1024)

      notification.openWarningNotification('¡Advertencia!')(
        `Archivo supera el límite de ${maximumSizeReadable} MB.`,
      )

      return false
    } else if (fileList.length > 0 && file.file.status !== 'removed') {
      notification.openErrorNotification(
        '¡Debe eliminar primero el archivo anterior',
      )(`Antes de subir archivo, debe eliminar el anterior`)

      return false
    } else if (file.file.status === 'removed') return false

    const fileWithName = {
      ...file,
      documentName: document,
    }

    setFiles([...files, fileWithName])
    setFileList([
      {
        url: '',
        name: `${document}.pdf`,
        uid: file.file.uid,
        size: file.file.size,
        type: file.file.type,
        originFileObj: {
          lastModifiedDate: new Date(),
          name: `${document}.pdf`,
          size: file.file.size,
          uid: file.file.uid,
          lastModified: file.file.size
        } as RcFile
      },
    ])
  }
  const handleRemove = () => {
    setFileList([])
    setFiles(
      files.filter(file => {
        return file.documentName !== document
      }),
    )
  }

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Upload
              accept="application/pdf"
              beforeUpload={() => {
                return false
              }}
              name={document}
              listType="text"
              onChange={handleChangeUpload}
              onRemove={handleRemove}
              fileList={fileList}
              scMargin>
              <Button icon={<CloudUploadOutlined />} />
            </Upload>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default UploadInputOnSave
