import React from 'react'
import { ApolloProvider } from '@apollo/client'

import { IApollo } from '../../Types/ProviderTypes'

const Apollo: React.FC<IApollo> = ({ children, client }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

export default Apollo
