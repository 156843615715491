import {
  Checkbox as CheckboxAnt,
  DatePicker as AntDatePicker,
  Input as AntInput,
  Select as AntSelect,
} from 'antd'
import styled, { css } from 'styled-components'

const { TextArea } = AntInput
const { Option } = AntSelect

type DatePickerProps = {
  showline?: boolean
  name?: string
  disabled?: boolean
  inputReadOnly?: boolean
  format?: string[]
  defaultValue?: any
  onChange?: (value?: any) => void | any
  placeholder?: string | any
  autoComplete?: string
  width?: 'default' | 'small' | 'medium'
  fontSize?: 'default' | 'small' | 'medium'
}

export const TextAreaComponent = styled(TextArea)`
  width: 91% !important;
  &:hover {
    border-color: #abd838 !important;
  }
  &:focus {
    border-color: #abd838 !important;
  }
`

export const Checkbox = styled(CheckboxAnt)`
  &.ant-checkbox-wrapper {
    margin-left: -2px !important;
    margin-top: 15px !important;
  }
  .ant-checkbox + span {
    font-size: 14px !important;
    color: #4e4e4f !important;
    font-weight: normal !important;
  }
  .ant-checkbox-inner {
    border-color: #abd838 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: #abd838 !important;
    border-color: #abd838 !important;
  }
`

// Inputs

export const InputComponent = styled(AntInput)`
  margin-bottom: -10px !important;
  color: #898989 !important;
  width: 91%;
  height: 40px;
  border-radius: 6px !important;
  font-weight: 400 !important;
  background-color: #f5f5f5 !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const Input = styled(AntInput)`
  width: 91% !important;
  color: #898989;
  background-color: #f5f5f5 !important;
  height: 40px;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputProfessional = styled(AntInput)`
  width: 93% !important;
  color: #898989;
  background-color: #f5f5f5 !important;
  height: 40px;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputLarge = styled(AntInput)`
  width: 98% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputLargeSub = styled(AntInput)`
  width: 97% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

export const InputSub = styled(AntInput)`
  width: 91% !important;
  height: 40px;
  color: #898989;
  background-color: #f5f5f5 !important;
  border-radius: 6px !important;
  &:focus {
    border-color: #abd838 !important;
  }
  &:hover {
    border-color: #abd838 !important;
  }
`

// Selects
export const SelectComponent = styled(AntSelect)`
  width: 91% !important;
  .ant-select-selector {
    color: #898989 !important;
    border-radius: 6px !important;
    background-color: #f5f5f5 !important;
    font-weight: 400 !important;
    font-size: 14px;
    &:hover {
      border-color: #abd838 !important;
    }
  }
`

export const Options = styled(Option)`
  .ant-select-selection-search {
    font-weight: regular !important;
  }
`

// DatePicker

export const DatePicker = styled(AntDatePicker)<DatePickerProps>`
  width: ${props =>
    props && props.width && props.width === 'small'
      ? '84%'
      : props.width === 'medium'
      ? '87%'
      : '91%'};
  height: 40px;
  ${props => props && props.showline &&
    css`
      text-decoration: line-through !important;
    `
  }
  background-color: #f5f5f5 !important;
  ${props =>
    !props.disabled
      ? css`
          .ant-picker-input > input {
            color: #898989 !important;
            cursor: pointer;
          }
          &:hover {
            border-color: #abd838 !important;
          }
          &:focus {
            border-color: #abd838 !important;
          }
        `
      : css`
          .ant-picker-input > input {
            cursor: not-allowed !important;
          }
          &:hover {
            border-color: #ff5b5b !important;
          }
          &:focus {
            border-color: #ff5b5b !important;
          }
        `};
  border-radius: 6px !important;
  ${props =>
    props && props.fontSize && props.fontSize === 'small'
      ? css`
          .ant-picker-input > input {
            font-size: 12.5px !important;
          }
        `
      : props.fontSize === 'medium'
      ? css`
          .ant-picker-input > input {
            font-size: 13px;
          }
        `
      : css`
          .ant-picker-input > input {
            font-size: 14px;
          }
        `};
`
