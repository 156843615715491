import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { getAuthToken } from './../Helpers/Token'

// Instantiate required constructor fields
const cache = new InMemoryCache({
  addTypename: false,
})

const httpLink = createHttpLink({
  uri: ({ operationName }) =>
    `${process.env.REACT_APP_URL_ENDPOINT}?q=${operationName}` ||
    `https://v0jumqn2ie.execute-api.us-east-1.amazonaws.com/development/graphql?q=${operationName}`,
})

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getAuthToken()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? token.Authorization : '',
    },
  }
})

const client = new ApolloClient({
  // Provide required constructor fields
  link: authLink.concat(httpLink),
  cache,

  // Provide some optional constructor fields
})

export { client as Client }
