import { Button, Card, Divider, Spin, Table } from 'antd'
import styled, { css } from 'styled-components'

export const StyledHeaderReportingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 15px 5px 15px;
`

export const StyledTitleHeaderReporting = styled.div`
  display: flex;
  h1 {
    color: #2d3f8c;
    font-size: 30px;
  }
  span {
    color: #d1d1d1;
    font-size: 17px;
    margin: 15px 0 0 35px;
  }
`

export const StyledButtonHeaderReporting = styled(Button)`
  width: 28%;
  height: 50px;
  background: #2d3f8c;
  margin-top: 5px !important;
  color: red !important;
  border: 1.5px solid #2d3f8c;
  padding: 0px 40px;
  color: #2d3f8c !important;
  .ant-btn {
    border-radius: 7px !important;
  }
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
`

export const StyledReportingContainer = styled.div`
  width: 100%;
  height: 100%;
  //provisorio
  /* padding: 15px 15px; */
  /* margin-bottom: 35px;//original */
`

export const StyledDashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

type CardOptionalProps = {
  $disabledProp?: boolean
}

export const StyledCard = styled(Card)<CardOptionalProps>`
  width: 100% !important;
  height: 100% !important;
  box-shadow: 5px 10px 18px #d5d4d3 !important;
  ${props =>
    !props.$disabledProp
      ? css`
          h3 {
            color: #2d3f8c !important;
            margin-bottom: 0px !important;
          }
          span {
            color: #d5d4d3;
            font-size: small;
          }
        `
      : ''}

  .ant-card-body {
    height: 100%;
  }
`

export const StyledIncomeAndChangesCard = styled(Card)`
  /* width: 300px !important;//provisorio */
  width: 100%;
  min-width: 280px;
  height: 100% !important;
  background: #ededed !important;
  h3 {
    color: #2d3f8c !important;
    margin-bottom: 0px !important;
  }
  span {
    color: #b9b8b7;
    font-size: small;
    margin: 0 14px 0 0;
  }
`

type Divider = {
  $marginBottom?: number
}

export const StyledDividerReporting = styled(Divider)<Divider>`
  margin-top: 10px;
  ${props =>
    props.$marginBottom
      ? css`
          margin-bottom: ${props.$marginBottom}px;
        `
      : ''}
`

export const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s !important;
`

export const StyledDividerIncome = styled(Divider)`
  margin-top: 32px !important;
`

export const StyledSpin = styled(Spin)`
  margin: 50px 0 0 500px;
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
  .ant-spin-dot {
    font-size: 90px;
  }
`

export const StyledTableDetails = styled(Table)`
  tr:nth-child(2n) {
    :hover {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    td {
      background-color: #e6e6e6 !important;
    }
  }
  tr:nth-child(2n + 1) {
    :hover {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  thead {
    background-color: #f1f1f1;
  }
  thead tr th {
    color: #2d3f8c;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    background: #f2f2f2;
  }
  .ant-pagination-item-active {
    border-color: #abd838 !important;
    a {
      color: #abd838 !important;
    }
  }
  a:hover {
    color: #abd838 !important;
    border-color: #abd838 !important;
  }
  &:hover {
    a {
      border-color: #abd838 !important;
    }
  }
  .ant-table-filter-trigger.active {
    color: #abd838 !important;
  }
`
