import React from 'react'
import { Col } from 'antd'
import {
  StyledFormItem,
  StyledSelectComponent,
} from 'App/GlobalComponents/styles'

import { PRIVILEGES_STATUSES } from '../../data'
import { renderMultipleOptions } from '../../helper'

 const PrivilegeServices: React.FC = () => (
  <Col span={8}>
    <StyledFormItem label="Privilegio Servicios" name="privilegeServices">
      <StyledSelectComponent
        mode="multiple"
        placeholder="Privilegio Servicios"
        allowClear
        size="large">
        {PRIVILEGES_STATUSES
          ? renderMultipleOptions(PRIVILEGES_STATUSES)
          : null}
      </StyledSelectComponent>
    </StyledFormItem>
  </Col>
)

export default PrivilegeServices
