import { BarChart } from 'recharts'
import styled from 'styled-components'

type Heading4 = {
    $headingColor: string;
}

export const StyledDetailsTableContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  height: 100%;
  margin: 5px 0 20px 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 3px 2px #DBDBDB;
`

export const StyledDetailsContainer = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  height: 295px;
  margin: 5px 0 20px 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 3px 2px #DBDBDB;
`

export const StyledContainer = styled.div`
  margin: 0 30px 0 30px;
`

export const StyledDetailsTitle = styled.div`
  padding: 25px 0 0 25px;
  h1 {
    color: #495FA4;
    font-size: 25px;
  }
  h3 {
    color: #B9B8B7;
    margin: -15px 0 10px 0;
  }
  p {
    color: #4E4E4F;
    margin-bottom: 0;
  }
  span {
    color: #495fa4;
    font-size: 60px;
  }
`

export const StyledBarChartDetails = styled(BarChart)`
  top: 30px;
  right: 20px;
  left: 10px;
  bottom: 5px;
  .recharts-legend-item-text {
    color: #454545 !important;
    font-weight: bold;
  }
  .recharts-default-legend {
    margin: -27px 0 0 55px !important;
  }
  .recharts-tooltip-wrapper {
    font-weight: bold;
    color: #454545;
  }
`

export const StyledHeading4 = styled.h4<Heading4>`
  color: ${(props: Heading4) =>
    props.$headingColor ? props.$headingColor : '#abd838'}; //  !important;
  font-weight: bold;
`
