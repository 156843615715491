import {
  Modal as ModalAnt,
  Progress as ProgressAnt,
  Spin as SpinAnt,
  Upload as UploadAnt,
} from 'antd'
import styled, { css } from 'styled-components'

type UploadProps = {
  scMargin?: boolean
  scHidden?: boolean
}

export const Upload = styled(UploadAnt)<UploadProps>`
  .ant-upload-list-item-name {
    color: #898989 !important;
  }
  .ant-upload-text-icon > .anticon {
    color: #898989 !important;
  }
  ${props =>
    props.scMargin &&
    css`
      .ant-upload-list-item {
        margin-left: 35px !important;
        margin-top: -26px !important;
      }
    `}
  .ant-btn {
    &:hover {
      border-color: #abd838 !important;
      color: #abd838 !important;
    }
    &:focus {
      border-color: #abd838 !important;
      color: #abd838 !important;
    }
  }
  ${props =>
    props.scHidden &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`

export const Spin = styled(SpinAnt)`
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
`

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  &:hover {
    color: red;
  }
`

export const Progress = styled(ProgressAnt)`
  position: absolute;
  top: 6px;
  width: 80%;
  margin-left: 8px;
`

export const ModalStyled = styled(ModalAnt)`
  width: 75%;
  .ant-btn-primary {
    color: red !important;
    background: red !important;
  }
  .ant-modal-confirm-content {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
`
