/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from '@apollo/client'

/**
 * This Hook receives the query and receives the query variables
 *
 * @param {*} [query] { The GraphQL Query}
 * @param {*} [variables] { variables: { example: 'example' } }
 * @return {*} { data, loading, error, refetch } { returns a response from API }
 *
 */
const useGetHook = (query?: any, variables?: any) => {
  const { data, loading, error, refetch } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only'
  })

  return {
    data,
    loading,
    error,
    refetch,
  }
}

export default useGetHook
