import { FC } from 'react'

import { HeaderPrivilegesServicesDetails } from '../services/HeaderPrivilegesServicesDetails'

import { PrivilegesDepartmentsDetailsContent } from './PrivilegesDepartmentsDetailsContent'


export const DepartmentsPrivilegesDetails: FC = () => {
    return (
        <>
            <HeaderPrivilegesServicesDetails />
            <PrivilegesDepartmentsDetailsContent
                title="Privilegios por Departamentos"
                subtitle="Personal Médico Activo actualmente"/>
        </>
    )
}
