import { gql } from '@apollo/client'

export const GET_ALL_HISTORY = gql`
  query allHistories($professional: ID!, $event: HistoryEvents) {
    allHistories(professional: $professional, event: $event) {
      _id
      event
      entity {
        name
        _id
      }
      professional {
        _id
        firstName
      }
      user {
        firstName
      }
      meta {
        name
        value
        values {
          name
          value
        }
      }
      createdAt
    }
  }
`

export const GET_ALL_HISTORIES_BY_ENTITY_NAME = gql`
  query allHistories ($professional: ID!, $entity: HistoryEntityInput) {
    allHistories(professional: $professional, entity: $entity) {
      _id
      event
      entity {
        name
        _id
        entityType
      }
      professional {
        _id
        firstName
      }
      user {
        firstName
      }
      meta{
        name
        value
        values {
          name
          value
        }
      }
      createdAt
    }
  }
`


export const GET_ALL_HISTORIES_BY_ENTITY_NAME_AND_EVENT = gql`
  query allHistories ($professional: ID!, $entity: HistoryEntityInput, $event: HistoryEvents) {
    allHistories(professional: $professional, entity: $entity, event: $event) {
      _id
      event
      entity {
        name
        _id
        entityType
      }
      professional {
        _id
        firstName
      }
      user {
        firstName
      }
      meta{
        name
        value
        values {
          name
          value
        }
      }
      createdAt
    }
  }
`
