import { ModalFuncProps } from 'antd/es/modal'

import * as Styled from './styles'

export const confirmPrompt = ({
  title,
  okText,
  cancelText,
  width,
  content,
  onOk,
  onCancel
}: ModalFuncProps): { destroy: () => void } =>
  Styled.ModalStyled.confirm({
    title,
    okText,
    cancelText,
    width,
    content,
    onOk,
    onCancel
  })

export const deleteItem = (fn: { (): void; (): void }, field: string): void => {
  confirmPrompt({
    onOk: fn,
    title: `¿Desea Eliminar ${field}?`,
    okText: 'Si, quiero continuar',
    cancelText: 'No',
    width: '450px',
    content: `Se eliminará ${field}, ¿Seguro desea continuar?`,
  })
}
