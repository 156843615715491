import { UploadComponent } from './component/UploadComponent'
import { UploadInputProps } from './type'

const UploadInput = ({
  professional,
  documentName,
  dataUploadsList,
  loadingUploadsList,
  errorUploadsList,
  refetchUploadsList,
  listType,
  disabled = false,
  authorizedBy,
  authorizedById,
  authorizedByName,
  refetchProfessional,
}: UploadInputProps) => {
  return (
    <UploadComponent
      documentName={documentName}
      dataUploadsList={dataUploadsList}
      listType={listType}
      professional={professional}
      refetchProfessional={refetchProfessional}
      refetchUploadsList={refetchUploadsList}
      errorUploadsList={errorUploadsList}
      loadingUploadsList={loadingUploadsList}
      authorizedById={authorizedById}
      authorizedBy={authorizedBy}
      authorizedByName={authorizedByName}
      disabled={disabled}
    />
  )
}

export default UploadInput
