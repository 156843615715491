import { Button, Checkbox, Switch } from 'antd'
import styled from 'styled-components'

export const StyledGeneralInformationContainer = styled.div`
  width: 50% !important;
  h1 {
    color: #abd838;
    font-weight: bold;
  }
`
interface SwitchViewProps extends React.HTMLAttributes<HTMLDivElement> {
  switchView: boolean
}

export const StyledSwitchContainer = styled.div<SwitchViewProps>`
  display: flex;
  border: 1px solid #d5d5d5;
  padding: 15px 20px;
  margin: 0 25% 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${props => (props.switchView ? 0 : 10)}px;
  border-bottom-right-radius: ${props => (props.switchView ? 0 : 10)}px;
  h4 {
    color: #4e4e4f;
    margin: 0 0 0 15px;
  }
`

export const StyledSwitch = styled(Switch)`
  .ant-switch {
    .ant-switch-checked {
      background-color: red !important;
    }
  }
`

export const StyledCustomReportContainer = styled.div`
  border: 1px solid #d5d5d5;
  padding: 15px 20px;
  margin: 0 25% 0 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const StyledCheckboxGeneralInformation = styled(Checkbox)`
  .ant-checkbox + span {
    font-size: 14px !important;
    color: #4e4e4f !important;
    font-weight: normal !important;
  }
  .ant-checkbox-inner {
    border-color: #abd838 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: #abd838 !important;
    border-color: #abd838 !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #abd838 !important;
  }
`

export const StyledListCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledContainer = styled.div`
  margin: -60px -495px 0 665px;
`

export const StyledCreateReportButton = styled(Button)`
  width: 100%;
  min-width: 24vw;
  height: 40px;
  background: #2d3f8c;
  border-radius: 5px;
  margin-top: 5px !important;
  border: 1.5px solid #2d3f8c;
  padding: 0 40px;
  color: #2d3f8c !important;
  .ant-btn {
    border-radius: 25px !important;
  }
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: #4e63ba;
    color: #2d3f8c !important;
  }

  &:active {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
  &:focus {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
`
