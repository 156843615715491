import { gql } from 'apollo-boost'

export const GET_UPLOADS = gql`
  query uploads ($code: Int!) {
    uploads(code: $code) {
      size
      key
    }
  }
`

export const GET_UPLOAD_FETCH_URL = gql`
  query uploadFetchUrl ($key: String!) {
    uploadFetchUrl(key: $key)
  }
`

// uploadDeleteUrl
export const DELETE_UPLOAD = gql`
  query uploadFetchUrl ($key: String!) {
    uploadFetchUrl (key: $key)
  }
`

export const UPLOAD_URL = gql`
  query uploadUrl ($code: Int!, $filename: String!) {
    uploadUrl(code: $code, filename: $filename)
  }
`
