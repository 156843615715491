import { ColumnsType } from 'antd/lib/table'

import { AccreditedColumnsType } from './types'

export const columns: ColumnsType<AccreditedColumnsType> = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    // render: (text: string, record: AccreditedColumnsType): React.ReactNode =>
    //   renderTextLink(text, record),
  },
  {
    title: 'RUT',
    dataIndex: 'rut',
    // render: renderThis,
  },
  {
    title: 'ID médico',
    dataIndex: 'code',
  },
  {
    title: 'Categoría',
    dataIndex: 'categories',
  },
  {
    title: 'Servicio',
    dataIndex: 'services',
  },
  {
    title: 'Departamento',
    dataIndex: 'department',
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    filters: [
      { text: 'Acreditado No Activo', value: 'Acreditado No Activo' },
      { text: 'Acreditado', value: 'Acreditado' },
      { text: 'Egresado', value: 'Egresado' },
      { text: 'Suspendido', value: 'Suspendido' },
    ],
    onFilter: (value, record) => record.status?.indexOf(value as string) === 0,
  },
]
