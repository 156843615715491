import { Form, Input } from 'antd'
import { INewPassword } from 'App/Types/LoginScreenTypes'

import * as Styled from '../../styles'

const NewPassword = ({
  onFinish,
  onFinishFailed,
  userName,
  loading,
  setConfirmNewPassword,
  setUserName,
  setNewPassword,
}: INewPassword): any => (
  <>
    <Styled.TitleHeader>Ingresa tu nueva contraseña</Styled.TitleHeader>
    <Form
      layout="vertical"
      name="newPassword"
      onFinish={onFinish}
      autoComplete="off"
      onFinishFailed={onFinishFailed}>
      <Styled.FormItem label="Email" name="username" initialValue={userName}>
        <Input
          value={userName}
          className="customeInput"
          disabled
          onChange={(e: any) => setUserName(e.target.value)}
          placeholder="Usuario"
        />
      </Styled.FormItem>

      <Styled.FormItem
        label="Nueva Contraseña"
        name="newPassword"
        rules={[
          {
            required: true,
            message: 'Porfavor ingrese su contraseña',
          },
        ]}>
        <Input.Password
          onChange={e => setNewPassword(e.target.value)}
          placeholder="Nueva Contraseña"
        />
      </Styled.FormItem>
      <Styled.FormItem
        label="Confirme Nueva Contraseña"
        name="confirmNewPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: 'Por favor confirma tu contraseña',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value)
                return Promise.resolve()

              return Promise.reject('Las contraseñas no coinciden')
            },
          }),
        ]}>
        <Input.Password
          onChange={e => setConfirmNewPassword(e.target.value)}
          placeholder="Confirme Nueva Contraseña"
        />
      </Styled.FormItem>

      <Form.Item>
        <Styled.LoginButton
          type="ghost"
          htmlType="submit"
          block
          loading={loading}>
          Ingresar
        </Styled.LoginButton>
      </Form.Item>
    </Form>
  </>
)

export default NewPassword
