import ObjectID from 'bson-objectid'

export const generateNewObjectId = (): string =>
  new ObjectID(new Date().getTime()).toHexString()

export const RUT = 'RUT'

export const STATUS_ACTIVE_ACCREDITED = 'ACTIVE_ACCREDITED' //

export const STATUS_IN_APPLICATION = 'IN_APPLICATION'

export const STATUS_DISCHARGED = 'DISCHARGED' //

export const STATUS_NON_ACTIVE_ACCREDITED = 'NON_ACTIVE_ACCREDITED' //

export const STATUS_SUSPENDED = 'SUSPENDED' //

// LoginScreen Constants
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'

export const NotAuthorizedException = 'NotAuthorizedException'

export const UserNotFoundException = 'UserNotFoundException'

// Objects
export const initialSubSpecialty = {
  subSpecialty: undefined,
  institution: undefined,
  certificatedAt: undefined,
  other: '',
  otherHidden: true,
  document: {
    path: '',
    displayableName: '',
    uploadedByName: '',
  },
}

export const initialContractType = {
  contract: undefined,
  endDate: undefined,
  startDate: undefined,
  authorizedBy: undefined,
  document: {
    displayableName: '',
  },
}

export const initialDevelopment = {
  development: undefined,
  institution: undefined,
  certificatedAt: null,
  otherDevelopment: undefined,
  otherInstitution: undefined,
  otherDevelopmentHidden: true,
  otherInstitutionHidden: true,
  document: {
    path: '',
    displayableName: '',
    uploadedByName: '',
  },
}

export const initialNonCompetitiveInstitutions = {
  institution: undefined,
  typeActivity: 'PRIMARY_HEALTH',
  startDate: undefined,
  endDate: undefined,
  lastest: false,
  other: '',
  otherHidden: true,
  document: {
    displayableName: '',
  },
}

export const initialTeachingActivities = {
  university: '',
  typeTeaching: undefined,
  startDate: undefined,
  endDate: undefined,
  lastest: false,
  document: {
    displayableName: '',
  },
}

export const initialChildren = {
  fullName: '',
  rut: undefined,
  birthday: undefined,
  document: {
    displayableName: '',
  },
}

export const initialOperatives = {
  operative: '',
  startDate: undefined,
  endDate: undefined,
  document: {
    displayableName: '',
  },
}

export const initialDesignations = {
  designationName: '',
  startDate: undefined,
  endDate: undefined,
  document: {
    displayableName: '',
  },
}

export const initialCommonCourse = {
  typeCourse: undefined,
  startDate: undefined,
  endDate: undefined,
  document: {
    displayableName: '',
  },
}

export const initialInfectionCourse = {
  typeCourseInfection: undefined,
  startDate: undefined,
  endDate: undefined,
  document: {
    displayableName: '',
  },
}

export const initialAccreditationResearch = {
  type: undefined,
  name: undefined,
  realizationDate: undefined,
  document: {
    displayableName: '',
  },
}

// Arrays
// export const ALLOWED_SELECTS = ['institution', 'development', 'companies', 'university']
export const ALLOWED_SELECTS = [
  'institution',
  'development',
  'developments',
  'companies',
]

export const initialBackupDocuments = {
  documentName: undefined,
  document: undefined,
}
