import * as React from 'react'
import { StyledSelectOptions } from 'App/GlobalComponents/styles'
import { v4 as uuidv4 } from 'uuid'

import { TypeSelect } from './types'

export const renderMultipleOptions = (
  options: TypeSelect[],
): React.ReactNodeArray =>
  options.map((option: TypeSelect) => (
    <StyledSelectOptions value={option.value} key={uuidv4()}>
      {option.text}
    </StyledSelectOptions>
  ))
