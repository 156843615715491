/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { notification } from 'antd'

const duration = 5

export const openSuccessNotification = (message = 'message empty') => (
  description = 'description empty',
) => {
  notification.success({
    message,
    description,
    duration,
  })
}

export const openErrorNotification = (message = 'message empty') => (
  description = 'description empty',
) => {
  notification.error({
    message,
    description,
    duration,
  })
}

export const openInfoNotification = (message = 'message empty') => (
  description = 'description empty',
) => {
  notification.info({
    message,
    description,
    duration,
  })
}

export const openWarningNotification = (message = 'message empty') => (
  description = 'description empty',
) => {
  notification.warning({
    message,
    description,
    duration,
  })
}
