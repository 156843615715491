import { gql } from '@apollo/client'

export const GET_ONE_PROFESSIONAL = gql`
  query oneProfessional($_id: ID!) {
    oneProfessional(_id: $_id) {
      _id
      firstName
      firstSurname
      firstSurname
      secondSurname
      nationality {
        _id
        name
        clcId
      }
      rut
      avatar
      status
      code
      birthday
      languages
      gender
      maritalStatus
      phone
      phoneCode
      cellPhone
      cellPhoneCode
      email
      corporativeEmail
      code
      address {
        region {
          _id
          name
        }
        city {
          _id
          name
        }
        commune {
          _id
          name
        }
        detail
      }
      createdAt
      startedAt
      detailTerm {
        endDate
        comment
        reason
      }
      department {
        _id
        department
        status
        realizationDate
        startDatePrivilege
        endDatePrivilege
      }
      services {
        _id
        service
        status
        realizationDate
        startDatePrivilege
        endDatePrivilege
      }
      centers {
        _id
        name
      }
      subDepartment {
        _id
        name
      }
      workplaces {
        _id
        name
      }
      contract {
        hours
        signedAt
        termAt
        typeContract
        address
      }
      categories {
        _id
        category
        startDate
      }
      positions {
        _id
        position
        startDate
        endDate
      }
      education {
        profession {
          _id
          name
        }
        university {
          _id
          name
        }
        sisCode
        graduatedAt
      }
      vaccine {
        dateAt
        status
        document {
          path
          displayableName
          uploadedByName
        }
        authorizedBy {
          _id
          firstName
          lastName
        }
      }
      radiological {
        categoryName
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
        authorizedBy {
          _id
          firstName
          lastName
        }
      }
      specialty {
        specialty {
          _id
          name
        }
        institution {
          _id
          name
        }
        certificatedAt
        otherHidden
        other
      }
      contractType {
        _id
        contract
        startDate
        endDate
        authorizedBy
      }
      subSpecialties {
        subSpecialty
        institution
        _id
        other
        otherHidden
        comment
        certificatedAt
      }
      liability {
        startDate
        endDate
        company {
          _id
          name
        }
        policyNumber
        other
      }
      teachingActivities {
        _id
        university
        typeTeaching
        startDate
        endDate
        lastest
      }
      nonCompetitiveInstitutionActivities {
        _id
        institution
        startDate
        endDate
        typeActivity
        lastest
        other
        otherHidden
        document {
          path
          displayableName
          uploadedByName
        }
      }
      institutionalOrientation {
        status
        realizationDate
      }
      careerPath {
        startDate
        appealedDate
        appealed
        document {
          path
          displayableName
          uploadedByName
        }
      }
      operatives {
        _id
        operative
        startDate
        endDate
      }
      developments {
        _id
        otherInstitutionHidden
        otherDevelopmentHidden
        institution
        development
        document {
          path
          displayableName
          uploadedByName
        }
        otherInstitution
        otherDevelopment
        certificatedAt
      }
      generalAssignments {
        pavilion
        outpatientProcedures
        medicalConsultation
        telemedicine
      }
      resuscitationCourses {
        _id
        typeCourse
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      infectionCourses {
        _id
        typeCourse
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      sedationCourses {
        _id
        typeCourse
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      extinguisherCourses {
        _id
        typeCourse
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      facilitySafetyCourses {
        _id
        typeCourse
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      otherDocuments {
        _id
        documentName
        document {
          displayableName
          uploadedByName
          path
        }
      }
      designations {
        _id
        designationName
        startDate
        endDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
      children {
        _id
        fullName
        rut
        birthday
        document {
          path
          displayableName
          uploadedByName
        }
      }
      accreditedResearch {
        _id
        type
        name
        realizationDate
        document {
          path
          displayableName
          uploadedByName
        }
      }
    }
  }
`
