import {
  Button,
  Col,
  Divider as AntDivider,
  Row,
  Spin as SpinAnt,
  Table as TableAnt,
  Tabs,
} from 'antd'
import styled from 'styled-components'

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
`

export const LoadingContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 200px;
`

export const Divider = styled(AntDivider)`
  background: #abd838 !important;
  height: 5px !important;
  margin: 10px 10px !important;
  width: 100% !important;
`

export const MenuComponent = styled(Tabs)`
  margin: 25px;
  background-color: #f9f9f9 !important;
`

export const HeaderContainerFile = styled.div`
  h1 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #abd838;
  }
`

export const RowFile = styled(Row)`
  margin: 0px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonHeaderBlueFile = styled(Button)`
  width: 300px;
  height: 50px;
  background: #2d3f8c;
  margin-top: 5px !important;
  color: red !important;
  border: 1.5px solid #2d3f8c;
  padding: 0px 40px;
  color: #2d3f8c !important;
   .ant-btn{
    border-radius: 25px !important;
  }
  span {
    font-weight: bold;
    color: #ffff !important;
  }
  &:hover {
    background: #4e63ba;
    color: #2d3f8c !important;
  }
`

export const ColFile = styled(Col)`
  margin-top: 40px !important;
`

export const ButtonContainerFile = styled.div`
  margin-top: -30px !important;
`

export const Postulation = styled.div`
  margin: 15px 15px;
`

export const Table = styled(TableAnt)`
  cursor: pointer;
  tr:nth-child(2n) {
    :hover {
      color: rgba(0, 0, 0, 0.5) !important;
    }
    td {
      background-color: #e6e6e6 !important;
    }
  }
  tr:nth-child(2n+1) {
    :hover {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  thead {
    background-color: #f1f1f1;
  }
  thead tr th {
    color: #2d3f8c;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    background: #f2f2f2;
  }
  .ant-pagination-item-active {
    border-color: #abd838 !important;
    a {
      color: #abd838 !important;
    }
  }
  a:hover {
    color: #abd838 !important;
    border-color: #abd838 !important;
  }
  &:hover {
    a {
      border-color: #abd838 !important;
    }
  }
  .ant-table-filter-trigger.active {
    color: #abd838 !important;
  }
`

export const Spin = styled(SpinAnt)`
  .ant-spin-dot-item {
    background-color: #abd838 !important;
  }
  .ant-spin-dot {
    font-size: 90px;
  }
`

export const ButtomPop = styled(Button)`
  span {
    color: #2d3f8c !important;
  }
`

export const StyledSpanPointer = styled.span`
  cursor: pointer;
`
