import { FC } from 'react'

import { HeaderMedicalCensusDepartmentsDetails } from './HeaderMedicalCensusDepartmentsDetails'
import { MedicalCensusDepartmentsDetailsContent } from './MedicalCensusDepartmentsDetailsContent'

export const DepartmentReportingDetails: FC = () => (
  <>
    <HeaderMedicalCensusDepartmentsDetails />
    <MedicalCensusDepartmentsDetailsContent
      title="Departamento"
      subtitle="Personal Médico Activo actualmente"
    />
  </>
)
