import { NavLink, useLocation } from 'react-router-dom'

import { Menu, MenuItem, StyledDistinctive } from './styles'

export const SideMenu = (): JSX.Element => {
  const { pathname } = useLocation()
  const environment = process.env.REACT_APP_ENVIRONMENT
  const pathnameMenuProfessional = [
    pathname &&
    (pathname.startsWith('/home') || pathname.startsWith('/profesional/'))
      ? '/home'
      : pathname,
  ]
  const pathnameMenuReport = [
    pathname &&
    (pathname.startsWith('/reporteria') ||
      pathname.startsWith('/crear-nuevo-reporte')) ||
        pathname.startsWith('/censo-medico-departamentos-detalles') ||
          pathname.startsWith('/censo-medico-servicios-detalles') ||
            pathname.startsWith('/privilegios-servicios-detalles') ||
              pathname.startsWith('/privilegios-departamentos-detalles') ||
              pathname.startsWith('/orientacion-institucional-detalles')
      ? '/reporteria'
      : pathname,
  ]
  const keyHomeProfessional = () =>
    pathname &&
    (pathname.startsWith('/home') || pathname.startsWith('/profesional/'))
      ? '/home' || '/profesional/'
      : ''
  const keyReport = () =>
    pathname &&
    (pathname.startsWith('/reporteria') ||
      pathname.startsWith('/crear-nuevo-reporte')) ||
        pathname.startsWith('/censo-medico-departamentos-detalles') ||
          pathname.startsWith('/censo-medico-servicios-detalles') ||
            pathname.startsWith('/privilegios-servicios-detalles') ||
              pathname.startsWith('/privilegios-departamentos-detalles') ||
              pathname.startsWith('/orientacion-institucional-detalles')
      ? '/reporteria' || '/crear-nuevo-reporte' || '/censo-medico-departamentos-detalles' || '/privilegios-servicios-detalles'
        || '/orientacion-institucional-detalles' || '/censo-medico-servicios-detalles' || '/privilegios-departamentos-detalles'
      : ''
  const renderDistinctive = () =>
    (!!environment && environment === 'preview') ||
    environment === 'staging' ? (
      <StyledDistinctive>
        <p>Ambiente de Pruebas</p>
      </StyledDistinctive>
    ) : (
      ''
    )

  return (
    <Menu
      mode="inline"
      selectedKeys={[...pathnameMenuProfessional, ...pathnameMenuReport]}
      defaultSelectedKeys={[pathname]}>
      <MenuItem key={keyReport()}>
        <NavLink to="/reporteria">
          <span>Reportería</span>
        </NavLink>
      </MenuItem>
      <MenuItem key={keyHomeProfessional()}>
        <NavLink to="/home">
          <span>Profesionales Médicos</span>
        </NavLink>
      </MenuItem>
      {renderDistinctive()}
    </Menu>
  )
}
